import React from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import { string, object, number, bool } from "prop-types";
import { Link } from "@quintype/components";

import DoubleRightIcon from "../../atoms/icons/double-angle-right";

import "./membership-button.m.css";

const MembershipButton = ({ publisherName, member, isActivePlan, activePlanId, isDarkTheme }) => {
  const closeHamburgerMenu = () => {
    document.getElementById("mySidenav").style.left = "-100%";
    document.body.style.overflowY = "auto";
  };
  return (
    <Link href="/plan-selection?utm_source=homepage&utm_medium=in_page_button" aria-label="Become a Member">
      <div styleName={isDarkTheme ? "wrapper dark" : "wrapper"} className="container" onClick={closeHamburgerMenu}>
        <h2 styleName={isDarkTheme ? "text-color" : ""}>
          {publisherName === "quint-hindi" ? "मेंबर बनें" : "BECOME A MEMBER"}
        </h2>
        <span styleName={isDarkTheme ? "icon-color" : ""}>
          <DoubleRightIcon />
        </span>
      </div>
    </Link>
  );
};

MembershipButton.propTypes = {
  className: string,
  publisherName: string,
  member: object,
  isActivePlan: number,
  activePlanId: number,
  isDarkTheme: bool,
};

const mapStateToProps = (state) => {
  return {
    member: state.member,
    isActivePlan: get(state, ["userReducer", "isActivePlan"], 0),
    activePlanId: get(state, ["userReducer", "activePlanId"], null),
    publisherName: get(state, ["qt", "config", "publisher-attributes", "name"], null),
    isDarkTheme: get(state, ["settingsReducer", "isDarkTheme"], false),
  };
};

export default connect(mapStateToProps, null)(MembershipButton);
