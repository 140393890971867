/* eslint-disable global-require */
import { startApp } from "@quintype/framework/client/start";
import { renderApplication, preRenderApplication } from "./render";
import wretch from "wretch";

import CustomReducers from "../isomorphic/components/helper/reducers";

import "../../app/assets/stylesheets/app.scss";

function enableHotReload(store) {
  if (process.env.NODE_ENV === "development" && module.hot) {
    module.hot.accept("./render", () => renderApplication(store));
  }
}

window.addEventListener("load", () => {
  window.dataLayer.push({ originalLocation: window.location.href });

  /* Delaying Scripts for Performance */
  const id = window.setTimeout(() => {
    const element = document.createElement("script");
    element.defer = true;
    element.src = "https://cdn.polyfill.io/v2/polyfill.min.js?features=default,IntersectionObserver,fetch";
    document.body.appendChild(element);

    const element2 = document.createElement("script");
    element2.defer = true;
    element2.src = "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    document.body.appendChild(element2);

    const element3 = document.createElement("script");
    element3.defer = true;
    element3.src = "https://cdn.gumlet.com/gumlet.js/2.0/gumlet.min.js";
    document.body.appendChild(element3);

    clearInterval(id);
  }, 3000);
  /* End */
});

global.wretch = wretch;
global.width = window.innerWidth;

startApp(renderApplication, CustomReducers, {
  enableServiceWorker: process.env.NODE_ENV === "production",
  appVersion: require("../isomorphic/app-version"),
  preRenderApplication,
}).then(enableHotReload);
