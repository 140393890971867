import React from "react";
import { func, object, bool } from "prop-types";
import { GiftBoxIconOne } from "../icons/gift-box-icons";
import "./profileImage.m.css";

const ProfileImage = ({ member, onClick, giftMembership }) => {
  const name = member["first-name"] || member.name;

  return (
    <div styleName="wrapper" onClick={onClick}>
      {giftMembership !== null && (
        <span styleName="notify">
          <GiftBoxIconOne />
        </span>
      )}
      <div styleName="profile-wrapper-circle">
        <span styleName="profile-circle">{name.charAt(0)}</span>
      </div>
    </div>
  );
};

ProfileImage.propTypes = {
  onClick: func,
  member: object,
  showAlert: bool,
  giftMembership: object,
};

export default ProfileImage;
