import React, { useEffect } from "react";
import { string, func, any, array, bool, number } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import { SearchBox } from "@quintype/components";
import SearchIcon from "../icons/search-icon/index";

import { setRecentSearch } from "../../helper/actions";

import "./search-input.m.css";

const DrawForm = ({ children }) => (
  <div styleName="search-input">
    <label htmlFor="searchForm">{children}</label>
    <button type="submit" styleName="search" aria-label="Search">
      Search
    </button>
  </div>
);

const DrawFormIcon = ({ children }) => (
  <div styleName="search-input-icon">
    <label htmlFor="searchForm">{children}</label>
    <button type="submit" styleName="search-icon" aria-label="Search">
      <SearchIcon height={26} width={26} />
    </button>
  </div>
);

DrawForm.propTypes = {
  children: any,
};

DrawFormIcon.propTypes = {
  children: any,
};

const SearchInput = ({ inputId, isSearchIcon, defaultValue, closeClick, list, setRecentSearch, merchantId }) => {
  useEffect(() => {
    document.getElementById(inputId).blur();
  }, []);

  const onSubmitClick = (text) => {
    if (text) {
      // If the top item and searched text is same then don't save as it same
      if (list[0] !== text) {
        const newList = [text, ...list];
        setRecentSearch(newList.slice(0, 5)); // Update Store
      }
    }

    if (closeClick) {
      document.getElementById(inputId).value = "";
      closeClick();
    }
  };

  return (
    <div styleName="wrapper" className="search-wrapper">
      <SearchBox
        placeholder="Search"
        styleName="search-box"
        inputId={inputId}
        inputClassName="search__form-input"
        template={isSearchIcon ? DrawFormIcon : DrawForm}
        initValue={defaultValue}
        onSubmitHandler={onSubmitClick}
      />
    </div>
  );
};

SearchInput.defaultProps = {
  inputId: "searchForm",
  isSearchIcon: false,
};

SearchInput.propTypes = {
  defaultValue: string,
  inputId: string,
  closeClick: func,
  list: array,
  setRecentSearch: func,
  isSearchIcon: bool,
  merchantId: number,
};

const mapStateToProps = (state) => ({
  list: get(state, ["settingsReducer", "recentSearchList"], []),
  merchantId: get(state, ["qt", "config", "publisher-attributes", "wru_id"]),
});

const mapDispatchToProps = (dispatch) => ({
  setRecentSearch: (list) => dispatch(setRecentSearch(list)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchInput);
