import React from "react";
import get from "lodash/get";
import { connect } from "react-redux";
import { string, bool, func } from "prop-types";

import TestingPopUp from "../../atoms/testing-popup-icon";

import "./testing-popup.m.css";

const TestPopUp = ({ publisherName, popUpVisible, setPopUpVisible }) => {
  const closeTestingPopup = () => {
    setPopUpVisible(false);
  };

  return (
    popUpVisible && (
      <>
        <div styleName="testing-container"></div>
        <div styleName="testing-popup">
          <span styleName="testing-popup-close" onClick={closeTestingPopup}>
            <button type="button" styleName="btn-close">
              <span styleName="icon-cross"></span>
            </button>
          </span>
          <div>
            <TestingPopUp />
          </div>
          <div>
            {publisherName === "quint-hindi"
              ? // eslint-disable-next-line max-len
                "नमस्कार, हमारी वेबसाइट पर कुछ परीक्षण चल रहा है। असुविधा के लिए हमे खेद है। लेकिन आप हमारे आर्टिक्ल्स को पढ़ना जारी रख सकते हैं!"
              : // eslint-disable-next-line max-len
                "Hello, our website is undergoing testing for a few minutes. We apologise for the inconvenience. But you can continue reading our articles as usual!"}
          </div>
        </div>
      </>
    )
  );
};

TestPopUp.propTypes = {
  publisherName: string,
  popUpVisible: bool,
  setPopUpVisible: func,
};

const mapStateToProps = (state) => {
  return {
    publisherName: get(state, ["qt", "config", "publisher-attributes", "name"], null),
  };
};

export default connect(mapStateToProps, null)(TestPopUp);
