import get from "lodash/get";

const getSectionSlug = (state, pageType) => {
  if (pageType === "story-page") {
    return get(state, ["data", "story", "sections", 0, "slug"], "NA");
  } else if (pageType === "section-page") {
    return get(state, ["data", "section", "slug"], "NA");
  }
  return "NA";
};

const getSectionId = (state, pageType) => {
  if (pageType === "story-page") {
    return get(state, ["data", "story", "sections", 0, "id"], "NA");
  } else if (pageType === "section-page") {
    return get(state, ["data", "section", "id"], "NA");
  }
  return "NA";
};

const getStoryId = (state, pageType) => {
  if (pageType === "story-page") {
    return get(state, ["data", "story", "id"], "NA");
  }
  return "NA";
};

const capitalize = (str) => {
  const capitalString = str.charAt(0).toUpperCase() + str.slice(1);
  return capitalString;
};
const getSectionList = (sections) => {
  if (sections) {
    const urlArray = get(sections, [0, "section-url"], "").split("/");
    const sectionList = [];
    if (urlArray.length > 4) {
      sectionList.push(capitalize(urlArray[urlArray.length - 1]));
      sectionList.push(capitalize(urlArray[urlArray.length - 2]));
    } else {
      sectionList.push(capitalize(urlArray[urlArray.length - 1]));
    }
    return sectionList;
  }

  return [];
};

const getTagsList = (tags) => {
  if (tags) {
    const tagList = tags.map((tag) => {
      if (tag.name.startsWith("#")) {
        return tag.name.slice(1);
      }
      return tag.name;
    });
    return tagList;
  }

  return [];
};

const getTagList = (state, pageType) => {
  if (pageType === "story-page") {
    const tags = get(state, ["data", "story", "tags"], []);
    const tagList = tags.map((item) => item.name);
    return tagList;
  }

  return [];
};

export const setViewportSizeMapping = (adSlot, googletag, viewPortSizeMapping) => {
  let mapping = googletag.sizeMapping().addSize(viewPortSizeMapping[0].viewport, viewPortSizeMapping[0].sizes).build();

  if (viewPortSizeMapping.length === 2) {
    mapping = googletag
      .sizeMapping()
      .addSize(viewPortSizeMapping[0].viewport, viewPortSizeMapping[0].sizes)
      .addSize(viewPortSizeMapping[1].viewport, viewPortSizeMapping[1].sizes)
      .build();
  }

  if (viewPortSizeMapping.length === 3) {
    mapping = googletag
      .sizeMapping()
      .addSize(viewPortSizeMapping[0].viewport, viewPortSizeMapping[0].sizes)
      .addSize(viewPortSizeMapping[1].viewport, viewPortSizeMapping[1].sizes)
      .addSize(viewPortSizeMapping[2].viewport, viewPortSizeMapping[2].sizes)
      .build();
  }

  adSlot.defineSizeMapping(mapping);
};

export const generateLazyLoading = (googletag, fetchMarginPercent, renderMarginPercent, mobileScaling) => {
  const updateSlotStatus = (slotId, state) => {
    const elem = document.getElementById(slotId + "-" + state);
    if (elem) {
      elem.className = "activated";
      elem.innerText = "Yes";
    }
  };

  if (googletag && googletag.pubadsReady) {
    // close empty divs
    // googletag.pubads().collapseEmptyDivs();
    // detect whether PubAdsService is fully loaded
    googletag.pubads().enableLazyLoad({
      fetchMarginPercent, // Fetch slots within specified viewports
      renderMarginPercent, // Render slots within specified viewports
      mobileScaling, // Multiplies the specified value with the above values for mobile
    });

    googletag.pubads().addEventListener("slotRequested", function (event) {
      updateSlotStatus(event.slot.getSlotElementId(), "fetched");
    });

    googletag.pubads().addEventListener("slotOnload", function (event) {
      updateSlotStatus(event.slot.getSlotElementId(), "rendered");
    });
  }
};

export const setTargetingParams = (googletag, adSlot, qtState, storySectionSlug) => {
  const pageType = get(qtState, ["pageType"], "");
  const environment = get(qtState, ["config", "publisher-attributes", "env"], "");
  const sectionSlug = storySectionSlug || getSectionSlug(qtState, pageType);
  const sectionId = getSectionId(qtState, pageType);
  const storyId = getStoryId(qtState, pageType);
  const sectionList = getSectionList(qtState, pageType);
  const tagList = getTagList(qtState, pageType);

  function getLanguage() {
    return get(qtState, ["config", "publisher-attributes", "lang"], "en");
  }

  adSlot
    .addService(googletag.pubads())
    .setTargeting("pageType", pageType)
    .setTargeting("environment", environment)
    .setTargeting("sectionSlug", sectionSlug)
    .setTargeting("sectionId", sectionId)
    .setTargeting("storyId", storyId)
    .setTargeting("sectionList", sectionList)
    .setTargeting("tagList", tagList)
    .setTargeting("language", getLanguage());
};

export const useDfpSlot = ({
  path,
  size,
  id,
  viewPortSizeMapping,
  env,
  publisherName,
  pageType,
  isDarkTheme,
  slotId,
  ...props
}) => {
  const googletag = window.googletag || {};
  googletag.cmd = googletag.cmd || [];

  googletag &&
    googletag.cmd.push(function () {
      const responsiveAdSlot = googletag && googletag.defineSlot(path, size, slotId);
      const theme = isDarkTheme ? "dark" : "white";
      if (responsiveAdSlot) {
        const { story, section } = { ...props };
        if (story && Object.keys(story).length !== 0) {
          const contextualArticle = story.seo["meta-keywords"].join();
          const section = story.sections ? getSectionList(story.sections).join() : "";
          const tags = story && story.tags;
          const tagsName = getTagsList(tags);
          googletag.enableServices();
          responsiveAdSlot
            .addService(googletag.pubads())
            .setTargeting("environment", env)
            .setTargeting("pageType", pageType)
            .setTargeting("publisherName", publisherName)
            .setTargeting("publisherId", 1)
            .setTargeting("theme", theme)
            .setTargeting("Contextual_Article", contextualArticle)
            .setTargeting("section", section)
            .setTargeting("tagName", tagsName);
        } else if (section && Object.keys(section).length !== 0) {
          const sectionName = get(section, ["metadata", "section", 0, "name"], "");
          const tags = get(section, ["metadata", "tags"], []);
          const tagsName = getTagsList(tags).join();
          googletag.enableServices();
          responsiveAdSlot
            .addService(googletag.pubads())
            .setTargeting("environment", env)
            .setTargeting("pageType", pageType)
            .setTargeting("publisherName", publisherName)
            .setTargeting("publisherId", 1)
            .setTargeting("theme", theme)
            .setTargeting("section", sectionName)
            .setTargeting("sectionName", sectionName)
            .setTargeting("tagName", tagsName);
        } else {
          googletag.enableServices();
          responsiveAdSlot
            .addService(googletag.pubads())
            .setTargeting("environment", env)
            .setTargeting("pageType", pageType)
            .setTargeting("publisherName", publisherName)
            .setTargeting("publisherId", 1)
            .setTargeting("theme", theme);
        }
        // setTargetingParams(window.googletag, responsiveAdSlot, qtState, storySectionSlug);
        // setViewportSizeMapping(responsiveAdSlot, window.googletag, viewPortSizeMapping);
        // if (enableLazyLoadAds) {
        //   generateLazyLoading(window.googletag, fetchMarginPercent, renderMarginPercent, mobileScaling);
        // }
      }
    });

  googletag &&
    googletag.cmd.push(function () {
      googletag && googletag.display(slotId);
    });
};

export const appendGoogleTagServices = () => {
  const script = document.createElement("script");
  const isSSL = document.location.protocol === "https:";
  script.src = (isSSL ? "https:" : "http:") + "//www.googletagservices.com/tag/js/gpt.js";
  const node = document.getElementsByTagName("script")[0];
  script.setAttribute("async", "");
  node.parentNode.insertBefore(script, node);
};

export const getAdSlots = ({
  path,
  size,
  id,
  viewPortSizeMapping,
  env,
  publisherName,
  pageType,
  isDarkTheme,
  slotId,
  ...props
}) => {
  // const adType = props.adtype;
  useDfpSlot({
    path: path,
    size: size,
    id: id,
    viewPortSizeMapping: viewPortSizeMapping,
    env: env,
    publisherName: publisherName,
    pageType: pageType,
    isDarkTheme: isDarkTheme,
    slotId: slotId,
    ...props,
  });
};

export const viewPortSizeMapping300x250 = [
  {
    viewport: [0, 0],
    sizes: [[300, 250]],
  },
];
