import { renderIsomorphicComponent, renderComponent, renderBreakingNews } from "@quintype/framework/client/start";

import { pickComponent } from "../isomorphic/pick-component";
import BreakingNewsView from "../isomorphic/components/rows/breaking-news-view";
import { LoadingIndicatorComponent } from "../isomorphic/components/atoms/loading-indicator";
import { Header } from "../isomorphic/components/header";
import { Footer } from "../isomorphic/components/layouts/footer";
// import { OutOfPageAd1 } from "../../app/isomorphic/components/rows/ad-free";

export function preRenderApplication(store) {
  const hydrate = { hydrate: !global.qtLoadedFromShell };
  renderComponent(Header, "header", store, hydrate);
  renderBreakingNews("breaking-news-container", store, BreakingNewsView, hydrate);
  renderComponent(LoadingIndicatorComponent, "loading-indicator", store);
  // renderComponent(OutOfPageAd1, "out-of-page-ad1", store);
  renderComponent(Footer, "footer", store, hydrate);
}

// This is a separate file as everything from here on is hot reloaded when the app changes
export function renderApplication(store) {
  renderIsomorphicComponent("container", store, pickComponent, {
    hydrate: !global.qtLoadedFromShell,
  });
}
