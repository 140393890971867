/* eslint-disable max-len */
import React from "react";
import { number, string, object } from "prop-types";

const QuintHindiLogo = ({ width = 120, height = 42, color = "#fff", member, isActivePlan, activePlanId }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 120 42">
    <path d="M0 0h120v42H0z" fill="rgba(255,255,255,0)" />
    <g id="Group_29983" transform="translate(5.405 5.386)">
      <path
        d="M21.285 31.592l-9.9-11.652s-4.1-.1-5.588-.2c-4.213-.3-6.013-2.99-5.827-6.874 0 0 0-5.479.2-7.969.217-3.78 2.117-5.078 6.01-4.88a73.838 73.838 0 0 1 8.6 1.094c2.406.5 4 1.6 4.9 4.782.4 1.592 1.3 6.163 1.708 8.266.4 2.292.312 4.782-3 5.377l2.9 12.06"
        fill="#fff"
        className="quint-hindi-logo-shape"
        transform="translate(.042 .002)"
      />
      <path
        d="M16.744 19.3a3.314 3.314 0 0 1-3.1-2.69c-.1-1.395-.4-5.677-.5-6.574-.1-1.3 1.1-1.991 2.4-1.794 1.2.2 2.6.994 2.7 2.39.2 1.494.7 4.879.9 6.373a2.088 2.088 0 0 1-2.4 2.292zm7.7-1c-.2-1.494-.8-4.583-1.2-6.373-.5-2.39-.6-4.583-3.8-5.179a55.379 55.379 0 0 0-8.9-.9c-2.9.1-3.1 2.39-3.2 3.983-.1 1.695 0 5.977 0 7.072.1 2.69 1 3.587 1.8 4.085 1.1.6 1.7.7 3.9.7.4 0 4.3.1 4.3.1l5.305 6.769-2.2-6.871a13.705 13.705 0 0 0 2.4-.1c1.7-.5 1.8-1.892 1.6-3.287z"
        fill="#fff"
        className="quint-hindi-logo-shape"
        transform="translate(-5.018 -4.036)"
      />
      <path
        d="M11.08 11.62a6.319 6.319 0 0 0-2.352 6.026c.1.927.827 2.369 2.456 1.7a4.587 4.587 0 0 0 1.757-1.391c.62-1.983 1.241-3.94 1.887-5.92a.645.645 0 0 0-.1-.18c-.879-1.365-2.456-1.082-3.645-.231zm14.5.824a6.945 6.945 0 0 0-.9 1.339 9.842 9.842 0 0 0-.569 1.751l-.026.129v-.026a5.186 5.186 0 0 0 0 2.446c.31 1 1.112 1.7 2.094 1.365a3.35 3.35 0 0 0 1.836-1.416l1.861-6.412c-.594-.67-1.991-1.571-4.291.824z"
        fill="none"
        fillRule="evenodd"
        transform="translate(24.459 4.898)"
      />
      <path
        d="M16.942 12.793c4.394-2.727 4.86.644 4.86.644a3.785 3.785 0 0 1 .258-.567c-1.5-2.7-4.369-.7-5.118-.077z"
        fill="#fff"
        transform="translate(24.983 4.935)"
      />
      <ellipse fill={color} cx="1.499" cy="1.493" rx="1.499" ry="1.493" transform="translate(55.677 4.853)" />
      <path
        d="M9.192 3.959A6.138 6.138 0 0 1 15.6 1c2.07.333 4.188 2.189 5.687 3.193A17.642 17.642 0 0 0 27.366 7.2a9.991 9.991 0 0 0 6.385-.257 6.921 6.921 0 0 0 1.99-1.413 3.757 3.757 0 0 0 .959-1.674l-.52-.206a3.051 3.051 0 0 1-1.24 1.442c-1.293.9-3.539 1.03-6.023.129a60.419 60.419 0 0 1-6.281-2.91C19.661.869 17.438-.032 14.491.094a9.215 9.215 0 0 0-4.834 1.571c-3.826 2.5-5.532 8.037-6.7 11.873C1.98 16.706 1.178 19.59.17 22.783h2.738c1.37-4.249 2.559-8.343 3.981-12.592.62-1.983 1.163-4.457 2.3-6.232z"
        fill={color}
        transform="translate(23.892 4.241)"
      />
      <path
        d="M41.1 11.055a1.93 1.93 0 0 1 1.448 1.829h2.843c.491-1.545.9-3.425 1.4-4.97H7.935c-.051.206-.13.412-.181.618h8.168c-.077.515-.362 1.468-.569 2.214a3.56 3.56 0 0 0-2.481-.721c-3.723.18-6.256 3.5-6.488 7.081-.13 1.906 1.034 3.605 2.766 3.682a4.658 4.658 0 0 0 3.673-1.571 67.777 67.777 0 0 0-1.034 3.013h2.947c.62-2.086 2.378-7.649 2.9-9.27 0 0 .388-1.264.543-1.906s.491-1.929.7-2.524h12.166c-.181.567-.413 1.725-.569 2.266a3.178 3.178 0 0 0-2.2-.8 6.261 6.261 0 0 0-2.973.644 4.208 4.208 0 0 0-.9.592 7.055 7.055 0 0 0-1.655 1.829c.026.026.026.051.051.077a10.818 10.818 0 0 0-.982 2.421 7.694 7.694 0 0 0-.284 1.88 3.164 3.164 0 0 0 2.947 3.4 4.18 4.18 0 0 0 3.361-1.545 403.53 403.53 0 0 1-.956 2.961h2.9s3.383-11.278 4.155-13.724h9.694c-.258.8-.491 2.214-.724 2.884a2.79 2.79 0 0 0-2.12-1.183c-3.619-.283-5.943 2.266-7.031 5.356-.62 1.695-.879 3.913.544 5.38A4.959 4.959 0 0 0 39.444 22a6.947 6.947 0 0 0 4.34-3.4c-.258-.1-.517-.18-.8-.283a6.3 6.3 0 0 1-4.524 3.116c-1.293.077-2.43-.386-2.43-2.343a12.268 12.268 0 0 1 1.189-4.481c.517-1.159 1.887-3.914 3.878-3.553zm-28 7.081a4.4 4.4 0 0 1-1.757 1.391c-1.6.67-2.326-.772-2.456-1.7a6.264 6.264 0 0 1 2.357-6.027c1.163-.85 2.766-1.159 3.619.231a.645.645 0 0 1 .1.18c-.594 1.983-1.241 3.94-1.861 5.92zm15.071.051A3.442 3.442 0 0 1 26.34 19.6c-.956.333-1.784-.361-2.094-1.365a5.186 5.186 0 0 1 0-2.446v.028l.026-.129a9.842 9.842 0 0 1 .569-1.751 6.945 6.945 0 0 1 .9-1.339c2.3-2.395 3.723-1.493 4.317-.85a766.98 766.98 0 0 0-1.887 6.437z"
        fill={color}
        transform="translate(24.296 4.717)"
      />
      <path
        d="M16.692 13.12s3.673-3.142 6.488-.309a10.366 10.366 0 0 0-1.913 2.389s-.467-3.373-4.86-.644c-.026 0 .413-1.442.284-1.442z"
        fill={color}
        transform="translate(24.948 4.943)"
      />
      <ellipse fill={color} cx="1.499" cy="1.493" rx="1.499" ry="1.493" transform="translate(89.828 4.903)" />
      <path
        d="M72.562 17.853c.181-.49.467-1.442.077-1.929a1.61 1.61 0 0 0-2.07-.026 2.814 2.814 0 0 0-1.06 2.421 7.284 7.284 0 0 0 .207 1.442c.051.1.077.18.13.283a4.211 4.211 0 0 0 2.716-2.191z"
        fill="none"
        fillRule="evenodd"
        transform="translate(28.277 5.229)"
      />
      <path
        d="M82.816 6.231v-.1a2.744 2.744 0 0 0-1.344-2.189 3.3 3.3 0 0 0-2.972.228c-.594.283-1.135.644-1.706.953-.259.13-3.749 2.319-4.808-1.466-.13.077-.284.154-.413.231a5.1 5.1 0 0 0 1.81 3.09c1.525 1 3.309.257 4.575-.8A14.7 14.7 0 0 1 79.3 5a1.142 1.142 0 0 1 .931-.231.744.744 0 0 1 .439.644 9.493 9.493 0 0 1-.257 1.487c-1.37 5.15-2.714 10.583-4.085 15.734h2.637q1.745-6.681 3.464-13.364a10.013 10.013 0 0 0 .388-3.038zm-31.33 1.493c.1-.361.181-.721.311-1.082.413-1.264 1.344-3.142 3-2.727a4.617 4.617 0 0 1 1.06.438c1.086.567 1.964 1.468 3.024 2.086a6.53 6.53 0 0 0 3.232 1 5.715 5.715 0 0 0 5.4-3.525 2.877 2.877 0 0 1-.413-.18 3.323 3.323 0 0 1-1.68 1.622 5.945 5.945 0 0 1-4.006.129 36.415 36.415 0 0 1-3.8-1.8 9.862 9.862 0 0 0-3.878-1.108 3.258 3.258 0 0 0-1.629.412 5.7 5.7 0 0 0-1.936 1.983A21.579 21.579 0 0 0 48 10.327c-1.247 4.118-2.511 8.212-3.752 12.332h2.559q2.481-7.262 4.6-14.6c.026-.129.051-.231.077-.333z"
        fill={color}
        transform="translate(26.632 4.44)"
      />
      <path
        d="M52.26 7.937l-.207.644h8.867c-.31.953-.569 1.519-.853 2.395.026-.077-.181-.333-.207-.386a2.206 2.206 0 0 0-1.318-.748 6 6 0 0 0-4.5.721 3.177 3.177 0 0 0-1.088 4.018c0 .026.026.026.026.051.026.1.077.18.077.154a3.621 3.621 0 0 0-.724.361 4.57 4.57 0 0 0-2.481 4.223c.155 3.038 4.136 3.3 6.385 2.678a7.253 7.253 0 0 0 2.507-1.288 7.681 7.681 0 0 0 .931-.9c.077-.1.827-1.159.8-1.159-.284-.026-.594-.026-.931-.051a4.408 4.408 0 0 1-.724.953c-1.68 1.622-4.5 2.291-5.868.515a2.525 2.525 0 0 1 .258-2.91 5.592 5.592 0 0 1 2.69-1.83 9.252 9.252 0 0 1 3.826-.515c.517.051 1.215.18 1.37.772a1.011 1.011 0 0 1-.077.67 4.894 4.894 0 0 1-.491.747h.827c.181-.231.284-.361.334-.412a2.816 2.816 0 0 0 .439-1.082c.13-1.545-1.757-2.009-2.947-2.111a11.385 11.385 0 0 0-2.081.053l-1.6.309a2.2 2.2 0 0 1 .155-2.214 2.306 2.306 0 0 1 4.083.774l.077-.026h3l1.4-3.766h10.457l-.646 2.723s-1.474-2.678-6.256 0c-2.947 1.648-4.963 5.511-3.878 8.78.749 2.3 3.361 2.528 5.351 1.835a1.538 1.538 0 0 0 .181-.077v.026a3.226 3.226 0 0 1-.362 2.369v.026a2.274 2.274 0 0 1-2.481.978s-.077.206-.13.412c-.026.077.646.18.7.206a2.815 2.815 0 0 0 .8-.026 3.773 3.773 0 0 0 2.4-1.131A2.955 2.955 0 0 0 71 23.519a4.679 4.679 0 0 0 .284-1.03 3.492 3.492 0 0 0 .026-1.264v-.051a9.53 9.53 0 0 1 .569-.206 5.882 5.882 0 0 0 1.241-.7 4.054 4.054 0 0 0 1.81-3.247 3.844 3.844 0 0 0-.181-1.313 1.713 1.713 0 0 0-.311-.515 2.262 2.262 0 0 0-1.551-.618 3.008 3.008 0 0 0-2.07.644 5.383 5.383 0 0 0-1.655 5.949c0 .026-3.8.9-2.9-3.708a10.27 10.27 0 0 1 2.714-5.3 5.592 5.592 0 0 1 .827-.644 2.586 2.586 0 0 1 .362-.206 9.109 9.109 0 0 1 1.008-.438 1.855 1.855 0 0 1 2.352 1.8h3.05l1.062-4.1a3.213 3.213 0 0 0 .181-.592zm19.592 8.423a1.61 1.61 0 0 1 2.07.026c.362.466.1 1.416-.077 1.929a4.491 4.491 0 0 1-2.738 2.214s-.155-.592-.155-.618a7.634 7.634 0 0 1-.13-1.107v-.026a2.728 2.728 0 0 1 1.03-2.418z"
        fill={color}
        transform="translate(26.995 4.768)"
      />
    </g>
  </svg>
);

QuintHindiLogo.propTypes = {
  width: number,
  height: number,
  color: string,
  member: object,
  isActivePlan: number,
  activePlanId: number,
};

export default QuintHindiLogo;
