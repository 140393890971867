import React from "react";

import { ResponsiveImage } from "@quintype/components";

const Facebook = () => (
  <ResponsiveImage
    slug="thequint/2024-07/49f403c8-794a-40cd-8647-b3e034361905/facebook (1).png"
    type="image/webp"
    alt="Facebook"
    aspectRatio={[1, 1]}
    defaultWidth={28}
    eager={false}
    imgParams={{ auto: ["format", "compress"] }}
    imageCDN="images.thequint.com"
  />
);

export default Facebook;
