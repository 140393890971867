import { getddMMyyyy, gethhmm } from "../helper/date-utils";
import { isPaywalled } from "../helper/story-elements-utils";
import { sortItems } from "./utils";

export const getNamesFromArray = (list = []) => {
  return list.map((author) => author.name).join("| ");
};

export const removeApostrophe = (string) => {
  return string.replace(/['‘’,]/g, "");
};

export const getTags = (tags) => {
  return tags && tags.map((tag) => tag.name).join("| ");
};

export function addGTM4PageVariables(story = {}, platform, storyIndex, page, section, event, historyId) {
  const defaultParams = {
    event: "pageDetails",
    story_details: {
      author: "N/A",
      page_type: "N/A",
      first_published_date: "N/A",
      first_published_time: "N/A",
      paywalled: "N/A",
      headline: "N/A",
    },
  };
  if (
    page &&
    page !== "story-page" &&
    page !== "section-page" &&
    page !== "tag-page" &&
    page !== "static-page" &&
    page !== "not-found"
  ) {
    const { name = "", slug = "" /* , metadata = "", tags = "", title = "", tag = "" */, id = "" } = section || {};
    const object = {
      ...defaultParams,
      event: event || "story_details",
      story_details: {
        ...defaultParams.story_details,
        page_type: page,
        section: name.charAt(0) + name.slice(1).toLowerCase() || slug || "N/A",
        paywalled: "No",
        current_url: historyId ? `${window.location.hostname + historyId}` : window.location.href || "N/A",
        section_id: id || "N/A",
        page_location: `${platform !== "AMP" ? window.location.href : "N/A"}`,
      },
    };
    window.dataLayer.push(object);
  }
  if (story) {
    const {
      "story-template": storyTemplate = "",
      authors = "",
      "first-published-at": firstPublishedAt = "",
      headline = "",
      id = "",
      sections = "",
      tags = "",
    } = story;
    const payWalled = story.access === "subscription" ? "Yes" : isPaywalled(story) ? "Archived" : "No";
    const authorString = getNamesFromArray(authors);
    const tagString = tags && tags.length && getTags(tags);

    const object = {
      ...defaultParams,
      event: event || "story_details",
      story_details: {
        ...defaultParams.story_details,
        author: authorString,
        page_type: `${(storyTemplate === null ? "text" : storyTemplate) || "N/A"}`,
        first_published_date: `${getddMMyyyy(firstPublishedAt).replace(",", "") || "N/A"}`,
        first_published_time: gethhmm(firstPublishedAt) || "N/A",
        paywalled: `${payWalled}`,
        headline: removeApostrophe(headline) || "N/A",
        story_id: id || "N/A",
        section: sections[0].name,
        section_id: `${sections[0].id}`,
        tags: tagString || "N/A",
      },
    };

    if (event === "read_more" || event === "more_news") {
      object[`${event + "_title"}`] = story.headline;
    }

    if (event === "become_member_paywalled") {
      object.paywalled_article = document.title;
    }

    if (platform === "AMP") {
      return object;
    } else {
      window.dataLayer.push(object);
    }
  }
}

export const userDetailsGTM = (member = {}, userPlans = [[]]) => {
  const object = {
    event: "user_details",
    user_details: {
      user_type: "anonymous",
      user_log_status: "N/A",
      social_login_status: "N/A",
      user_id: "N/A",
      userLoginMedium: "N/A",
    },
    planDetails: [],
  };

  if (member && member.id) {
    object.user_details.user_type = "non-member";
    object.user_details.user_log_status = "login";
    object.user_details.social_login_status = member["verification-status"] ? "Yes" : "No";
    object.user_details.user_id = member.id;
    object.user_details.userLoginMedium =
      (member && member["verification-status"] && member["verification-status"].split("-")[1]) || "email";
  }

  if (userPlans.subscriptions && userPlans.subscriptions.length) {
    // eslint-disable-next-line no-undef
    userPlans.subscriptions.sort(sortItems).forEach((item, index) => {
      object.planDetails.push({
        planAmount: item.plan_amount_cents / 100,
        planEndDate: item.end_timestamp,
        planId: item.id,
        planName: item.plan_name,
        planStartDate: item.start_timestamp,
        planStatus: item.status,
        planDuration: `${item.duration_length + " " + item.duration_unit}`,
        subscriber_id: item.subscriber_id,
        subscription_type: item.subscription_type,
      });
    });
  }

  if (object.planDetails.length && object.planDetails[0].planStatus === "active") {
    object.user_details.user_type = "member";
  }
  window && window.dataLayer && window.dataLayer.push(object);
};

export const topReadDL = (story, headline) => {
  const {
    access = "",
    "story-template": storyTemplate = "",
    authors = "",
    "first-published-at": firstPublishedAt = "",
    headline: storyHeadline = "",
    sections = "",
  } = story;

  const authorString = getNamesFromArray(authors);
  const payWalled = access === "subscription" ? "Yes" : "No";

  const object = {
    event: "top_reads",
    story_details: {
      author: `${authorString}`,
      page_type: `${(storyTemplate === null ? "text" : storyTemplate) || "N/A"}`,
      first_published_date: `${getddMMyyyy(firstPublishedAt).replace(",", "") || "N/A"}`,
      first_published_time: `${gethhmm(firstPublishedAt) || "N/A"}`,
      paywalled: `${payWalled}`,
      headline: `${storyHeadline}`,
      section: sections[0].name,
      current_url: window.location.href || "N/A",
      section_id: `${sections[0].id}`,
      page_location: window.location.href || "N/A",
      to_article: `${removeApostrophe(headline) || "N/A"}`,
    },
  };
  window.dataLayer.push(object);
};
