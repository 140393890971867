import React, { useState } from "react";
import { array, string, func, object, number } from "prop-types";
import { Link } from "@quintype/components";

import LinkView from "../../atoms/link-view";
import MenuItem from "../../atoms/menu-item";
import SearchInput from "../../atoms/SearchInput";
import ToggleTheme from "../../atoms/toggle-theme";
import CrossIcon from "../../atoms/icons/cross-icon";

import SocialIcons from "../../molecules/social-icons";

import translate from "../../translate";

import PopupMobileLogoView from "../mobile-popup-logos";
import { isBlackListerStory } from "../../utils/utils";
import "./mobilePopupMenu.m.css";
import MembershipButton from "../../rows/membership-button";

const MobilePopupMenu = ({
  hamburgerMenuItems,
  secondaryMenuItems,
  pageType,
  closeClick,
  navigateToPage,
  publisherName,
  currentHostUrl,
  currentPath,
  member,
  isActivePlan,
}) => {
  if (!(hamburgerMenuItems && hamburgerMenuItems.length)) {
    return null;
  }
  const [isDark, setToggleTheme] = useState(false);
  const menuItems = hamburgerMenuItems;

  const onHomeMenuClick = (e) => {
    e.preventDefault();

    closeClick();
    navigateToPage("/");
  };
  const getButtonsView = () => {
    const handleClick = () => {
      closeClick();
      window.localStorage.setItem("sym_initiate_page", currentPath || "/");
      window.localStorage.setItem("sym_initiate_position", "ATF - Header");
    };

    const getPlanSelectionUrlWithUtms = () => {
      let planSelectionUrl = `/plan-selection?utm_source=top_menu&utm_medium=insider_button`;

      if (publisherName !== "quint") {
        planSelectionUrl += `?utm_source=desktop_top&utm_medium=Internal_${
          publisherName === "fit" ? "FIT" : "HQ"
        }&utm_campaign=WebHooks`;
      }
      return planSelectionUrl;
    };

    const blacklist = ["garena", "wordle", "lottery", "brandstudio"];
    const regex = new RegExp(blacklist.join("|"), "gi");
    const isBlacklisted = regex.test(currentPath);

    return (
      <Link href={getPlanSelectionUrlWithUtms()} styleName="support-subscribe-btn" aria-label="Become Member page">
        {!isActivePlan && !isBlacklisted && (
          <div styleName="become-member" onClick={handleClick}>
            {translate(publisherName, "header-cta")}
          </div>
        )}
      </Link>
    );
  };
  return (
    <div styleName="wrapper">
      <div styleName="theme-close-btn">
        <ToggleTheme isDark={isDark} onToggle={setToggleTheme} type="mobile" />
        {getButtonsView()}
        <div styleName="cross-icon" onClick={closeClick}>
          <CrossIcon />
        </div>
      </div>

      <div styleName="mobile-logos-wrapper">
        <PopupMobileLogoView publisherName={publisherName} />
      </div>

      {!isBlackListerStory(currentPath) && <SearchInput closeClick={closeClick} isSearchIcon={true} />}

      <ul>
        {/* Show Home as default */}
        <li
          className="top-navbar-home"
          styleName={`menu-item ${pageType === "home-page" ? "highlighted-menu-item" : ""}`}
        >
          <div styleName="wrapper-link" onClick={onHomeMenuClick}>
            {translate(publisherName, "home")}
          </div>
        </li>

        {menuItems.map((item, index) => (
          <li className={`top-navbar-${index + 1}`} key={index} styleName="menu-item">
            <MenuItem
              item={item}
              isPopup={true}
              closePopup={closeClick}
              navigateToPage={navigateToPage}
              currentHostUrl={currentHostUrl}
            />
          </li>
        ))}
      </ul>

      {/* <SpecialProjectsNewsButton /> */}
      {!isBlackListerStory(currentPath) && <MembershipButton />}

      {/* Soical Icons */}
      <div styleName="follow-us-wrapper">
        <div styleName="follow-us-name">{translate(publisherName, "follow_us_on")}</div>
        <SocialIcons />
      </div>

      {/* Footer Links */}
      <div styleName="footer-links">
        <LinkView link="/about-us" openInNewTab={false} ariaLabel="About Us">
          {translate(publisherName, "about_us")}
        </LinkView>
        <LinkView link="/contact-us" openInNewTab={false} ariaLabel="Contact Us">
          {translate(publisherName, "contact_us")}
        </LinkView>
        <LinkView link="/privacy-policy" openInNewTab={false} ariaLabel="Privacy Policy">
          {translate(publisherName, "privacy_policy")}
        </LinkView>
      </div>
    </div>
  );
};

MobilePopupMenu.propTypes = {
  hamburgerMenuItems: array,
  secondaryMenuItems: array,
  pageType: string,
  closeClick: func,
  publisherName: string,
  navigateToPage: func,
  member: object,
  isActivePlan: number,
  onPopupSettingsClick: func,
  currentHostUrl: string,
  currentPath: string,
};

export default MobilePopupMenu;
