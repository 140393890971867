import { PAGE_TYPE } from "./constants";
import { pickComponentHelper } from "@quintype/framework/server/pick-component-helper";

const { pickComponent, getChunkName } = pickComponentHelper(
  {
    [PAGE_TYPE.HOME_PAGE]: { chunk: "list", component: "HomePage" },
    [PAGE_TYPE.SECTION_PAGE]: { chunk: "list", component: "SectionPage" },
    [PAGE_TYPE.BIG_STORY_SECTION_PAGE]: { chunk: "list", component: "SectionPage" },
    [PAGE_TYPE.TAG_PAGE]: { chunk: "list", component: "TagPage" },
    [PAGE_TYPE.AUTHOR_PAGE]: { chunk: "list", component: "AuthorPage" },
    [PAGE_TYPE.SEARCH_PAGE]: { chunk: "list", component: "SearchPage" },
    [PAGE_TYPE.STORY_PAGE]: { chunk: "story", component: "StoryPage" },
    [PAGE_TYPE.CATALOG_PAGE]: { chunk: "list", component: "CatalogPage" },
    [PAGE_TYPE.STORY_PREVIEW]: { chunk: "story", component: "StoryPagePreview" },
    [PAGE_TYPE.STORY_PUBLIC_PREVIEW_PAGE]: { chunk: "story", component: "StoryPage" },
    [PAGE_TYPE.HOME_PREVIEW]: { chunk: "list", component: "HomePagePreview" },
    [PAGE_TYPE.CAMPAIGN_PAGE_PAGE]: { chunk: "list", component: "CampaignPage" },
    [PAGE_TYPE.HINDI_CAMPAIGN_PAGE]: { chunk: "list", component: "HindiCampaignPage" },
    [PAGE_TYPE.MEMBERSHIP_PAGE]: { chunk: "list", component: "MembershipPage" },
    // [PAGE_TYPE.INDEPENDENCE_DAY_MEMBERSHIP]: { chunk: "list", component: "NewMembership" },
    [PAGE_TYPE.DASHBOARD_PAGE]: { chunk: "list", component: "DashboardPage" },
    [PAGE_TYPE.BOOKMARK_PAGE]: { chunk: "list", component: "BookMarkPage" },
    [PAGE_TYPE.UGC_FORM_PAGE]: { chunk: "list", component: "UgcFormPage" },
    [PAGE_TYPE.SUBSCRIBE_PAGE]: { chunk: "list", component: "SubscribePage" },
    [PAGE_TYPE.PAYMENT_PAGE_TEMP]: { chunk: "list", component: "PaymentSuccess" },
    [PAGE_TYPE.MEMBER_PAYMENT_SUCCES]: { chunk: "list", component: "MemberPaymentSuccess" },
    [PAGE_TYPE.MEMBER_PAYMENT_FAILURE]: { chunk: "list", component: "MemberPaymentFailed" },
    [PAGE_TYPE.MEMBER_PAYMENT_FAILURE_RECURRING]: { chunk: "list", component: "MemberPaymentFailedRecurring" },
    [PAGE_TYPE.CAMPAIGN_PAYMENT_SUCCES]: { chunk: "list", component: "CampaignSuccess" },
    [PAGE_TYPE.CAMPAIGN_PAYMENT_FAILURE]: { chunk: "list", component: "CampaignFailure" },
    [PAGE_TYPE.PAYMENT_PAGE]: { chunk: "list", component: "PaymentFailed" },
    [PAGE_TYPE.RESET_PASSWORD_PAGE]: { chunk: "list", component: "ResetPasswordView" },
    // [PAGE_TYPE.NOT_AVAILABLE_PAGE]: { chunk: "list", component: "NotAvailablePage" },

    default: { chunk: "list", component: "NotFoundPage" },
  },
  {
    list: () => import(/* webpackChunkName: "list" */ "./component-bundles/list.js"),
    story: () => import(/* webpackChunkName: "story" */ "./component-bundles/story.js"),
  }
);

export { pickComponent, getChunkName };
