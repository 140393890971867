/* eslint-disable camelcase */
import { getMobileNumber, getSectionAndSubsectionBySlug } from "../utils/utils";

export function capturePlansImpressionsNew(plans, coupon, publisherName) {
  const object = {
    event: "view_item_list",
    current_url: document.title,
    page_path: window.location.href,
    ecommerce: {
      item_list_id: publisherName === "quint-hindi" ? "hq_membership_plans" : "tq_membership_plans",
      item_list_name: publisherName === "quint-hindi" ? "HQ Membership Plans" : "TQ Membership Plans",
      items: plans.slice(0, 3).map((plan, ind) => ({
        item_id: plan.id,
        item_name: plan.title,
        affiliation: publisherName === "quint-hindi" ? "HQ-Membership Plans" : "TQ-Membership Plans",
        coupon: (coupon && coupon["coupon-name"]) || "N/A",
        discount:
          coupon && coupon.discount && coupon.discount.length
            ? ((plan.price_cents * 100) / (100 - coupon.discount[ind]) - plan.price_cents) / 100
            : "N/A",
        index: ++ind,
        item_brand: publisherName === "quint-hindi" ? "HQ Membership Plans" : "TQ Membership Plans",
        item_category: "Membership Plan",
        item_list_id: publisherName === "quint-hindi" ? "hq_membership_plans" : "tq_membership_plans",
        item_list_name: publisherName === "quint-hindi" ? "HQ Membership Plans" : "TQ Membership Plans",
        item_variant: "membership plan",
        location_id: "N/A",
        price: plan.price_cents / 100,
        quantity: 1,
      })),
    },
  };
  if (coupon && coupon.discount) {
    object.ecommerce.creative_name = coupon["coupon-name"];
    object.ecommerce.creative_slot = "membershipCoupon";
    object.ecommerce.promotion_id = coupon["coupon-code"];
    object.ecommerce.promotion_name = coupon["coupon-name"];
  }
  if (window && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(object);
  }
}

export const capturePlanImpressionAndSelect = (planObj, position, coupon, event, publisherName) => {
  const object = {
    event: event,
    current_url: document.title,
    page_path: window.location.href,
    ecommerce: {
      currency: "INR",
      value: planObj.price_cents / 100,
      coupon_percentage: coupon && coupon.discount && coupon.discount.length ? coupon.discount[position] : "",
      coupon: (coupon && coupon["coupon-name"]) || "N/A",
      items: [
        {
          item_id: planObj.id,
          item_name: planObj.title,
          affiliation: publisherName === "quint-hindi" ? "HQ-Membership Plans" : "TQ-Membership Plans",
          coupon: (coupon && coupon["coupon-name"]) || "N/A",
          discount:
            coupon && coupon.discount && coupon.discount.length
              ? ((planObj.price_cents * 100) / (100 - coupon.discount[position - 1]) - planObj.price_cents) / 100
              : "N/A",
          index: position,
          item_brand: publisherName === "quint-hindi" ? "HQ Membership Plans" : "TQ Membership Plans",
          item_category: "Membership Plan",
          item_list_id: publisherName === "quint-hindi" ? "hq_membership_plans" : "tq_membership_plans",
          item_list_name: publisherName === "quint-hindi" ? "HQ Membership Plans" : "TQ Membership Plans",
          item_variant: "membership plan",
          location_id: "N/A",
          price: planObj.price_cents / 100,
          quantity: 1,
        },
      ],
    },
  };
  if (coupon && coupon.discount) {
    object.ecommerce.creative_name = coupon["coupon-name"];
    object.ecommerce.creative_slot = "membershipCoupon";
    object.ecommerce.promotion_id = coupon["coupon-code"];
    object.ecommerce.promotion_name = coupon["coupon-name"];
  }
  if (window && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(object);
  }
};

export const capturePlansAddToCartDL = (plan, position, coupon, publisherName, gift = false) => {
  const object = {
    event: "add_to_cart",
    current_url: document.title,
    page_path: window.location.href,
    ecommerce: {
      currency: "INR",
      value: plan.price_cents / 100,
      coupon_percentage: coupon && coupon.discount && coupon.discount.length ? coupon.discount[position - 1] : "N/A",
      coupon: (coupon && coupon["coupon-name"]) || "N/A",
      items: [
        {
          item_id: plan.id,
          item_name: plan.title,
          affiliation: publisherName === "quint-hindi" ? "HQ-Membership Plans" : "TQ-Membership Plans",
          coupon: (coupon && coupon["coupon-name"]) || "N/A",
          discount:
            coupon && coupon.discount && coupon.discount.length
              ? ((plan.price_cents * 100) / (100 - coupon.discount[position - 1]) - plan.price_cents) / 100
              : "N/A",
          index: position,
          item_brand: publisherName === "quint-hindi" ? "HQ Membership Plans" : "TQ Membership Plans",
          item_category: gift ? "GiftMembership" : "Membership Plan",
          item_list_id: publisherName === "quint-hindi" ? "hq_membership_plans" : "tq_membership_plans",
          item_list_name: publisherName === "quint-hindi" ? "HQ Membership Plans" : "TQ Membership Plans",
          item_variant: "membership plan",
          location_id: "N/A",
          price: plan.price_cents / 100,
          quantity: 1,
        },
      ],
    },
  };
  if (coupon && coupon.discount) {
    object.ecommerce.creative_name = coupon["coupon-name"];
    object.ecommerce.creative_slot = "membershipCoupon";
    object.ecommerce.promotion_id = coupon["coupon-code"];
    object.ecommerce.promotion_name = coupon["coupon-name"];
  }
  if (gift) {
    object.ecommerce.creative_name = "GiftMembership";
    object.ecommerce.creative_slot = "GiftMembership";
    object.ecommerce.promotion_id = "GiftMembership";
    object.ecommerce.promotion_name = "GiftMembership";
  }
  if (window && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(object);
  }
};

export const capturePlansCheckOutDL = (
  planObj,
  position,
  coupon,
  paymentMethod,
  planType,
  publisherName,
  gift = false
) => {
  const object = {
    event: "begin_checkout",
    current_url: document.title,
    page_path: window.location.href,
    ecommerce: {
      currency: "INR",
      value: planObj.price_cents / 100,
      coupon: (coupon && coupon["coupon-name"]) || "N/A",
      coupon_percentage: coupon && coupon.discount && coupon.discount.length ? coupon.discount[position - 1] : "N/A",

      plan_type: planType,
      gateway: "razorpay",
      payment_mode: paymentMethod === "netbanking" ? "netbanking_others" : paymentMethod,
      items: [
        {
          item_id: planObj.id,
          item_name: planObj.title,
          affiliation: publisherName === "quint-hindi" ? "HQ-Membership Plans" : "TQ-Membership Plans",
          coupon: (coupon && coupon["coupon-name"]) || "N/A",
          discount:
            coupon && coupon.discount && coupon.discount.length
              ? ((planObj.price_cents * 100) / (100 - coupon.discount[position - 1]) - planObj.price_cents) / 100
              : "N/A",
          index: position,
          item_brand: publisherName === "quint-hindi" ? "HQ Membership Plans" : "TQ Membership Plans",
          item_category: gift ? "GiftMembership" : "Membership Plan",
          item_list_id: publisherName === "quint-hindi" ? "hq_membership_plans" : "tq_membership_plans",
          item_list_name: publisherName === "quint-hindi" ? "HQ Membership Plans" : "TQ Membership Plans",
          item_variant: "membership plan",
          location_id: "N/A",
          price: planObj.price_cents / 100,
          quantity: 1,
        },
      ],
    },
  };

  if (coupon && coupon.discount) {
    object.ecommerce.creative_name = coupon["coupon-name"];
    object.ecommerce.creative_slot = "membershipCoupon";
    object.ecommerce.promotion_id = coupon["coupon-code"];
    object.ecommerce.promotion_name = coupon["coupon-name"];
  }
  if (gift) {
    object.ecommerce.creative_name = "GiftMembership";
    object.ecommerce.creative_slot = "GiftMembership";
    object.ecommerce.promotion_id = "GiftMembership";
    object.ecommerce.promotion_name = "GiftMembership";
  }
  if (window && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(object);
  }
};

export const capturePlansPurchaseSuccessDL = (paymentData, planType, id, publisherName, gift = false) => {
  const totalAmount = paymentData.price_cents / 100;
  const razorpayGatewayTax = 0.0217 * totalAmount;
  const razorpayGst = 0.0039 * totalAmount;
  const baseAmount = totalAmount - (razorpayGatewayTax + razorpayGst);
  const totalGst = baseAmount - 0.84745762711 * baseAmount;
  const basePrice = baseAmount - totalGst;
  const totalTax = totalAmount - basePrice;
  const coupon = paymentData.activeCouponDetails || "N/A";
  const position = paymentData.position;

  const object = {
    event: "purchase",
    current_url: document.title,
    page_path: window.location.href,
    story_url: paymentData.story_url || "N/A",
    ecommerce: {
      user_id: id,
      transaction_id: paymentData.responeId,
      value: basePrice.toFixed(2),
      tax: totalTax.toFixed(2),
      total_gst: totalGst.toFixed(2),
      razorpay_gateway_tax: razorpayGatewayTax.toFixed(2),
      razorpay_gst: razorpayGst.toFixed(2),
      shipping: "shippingCharge",
      currency: "INR",
      coupon: (coupon && coupon["coupon-name"]) || "N/A",
      coupon_percentage: coupon && coupon.discount && coupon.discount.length ? coupon.discount[position - 1] : "N/A",
      plan_type: planType,
      gateway: "razorpay",
      payment_mode:
        paymentData.selectedPaymentOptions === "netbanking"
          ? "netbanking_others"
          : paymentData.selectedPaymentOptions || "N/A",

      items: [
        {
          item_id: paymentData.id,
          item_name: paymentData.title,
          affiliation: publisherName === "quint-hindi" ? "HQ-Membership Plans" : "TQ-Membership Plans",
          coupon: (coupon && coupon["coupon-name"]) || "N/A",
          discount:
            coupon && coupon.discount && coupon.discount.length
              ? (totalAmount * 100) / (100 - coupon.discount[position - 1]) - totalAmount
              : "N/A",
          index: position,
          item_brand: publisherName === "quint-hindi" ? "HQ Membership Plans" : "TQ Membership Plans",
          item_category: gift ? "GiftMembership" : "Membership Plan",
          item_list_id: publisherName === "quint-hindi" ? "hq_membership_plans" : "tq_membership_plans",
          item_list_name: publisherName === "quint-hindi" ? "HQ Membership Plans" : "TQ Membership Plans",
          item_variant: "membership plan",
          location_id: "N/A",
          price: totalAmount,
          quantity: 1,
        },
      ],
    },
  };

  if (coupon && coupon.discount) {
    object.ecommerce.creative_name = coupon["coupon-name"];
    object.ecommerce.creative_slot = "membershipCoupon";
    object.ecommerce.promotion_id = coupon["coupon-code"];
    object.ecommerce.promotion_name = coupon["coupon-name"];
  }
  if (gift) {
    object.ecommerce.creative_name = "GiftMembership";
    object.ecommerce.creative_slot = "GiftMembership";
    object.ecommerce.promotion_id = "GiftMembership";
    object.ecommerce.promotion_name = "GiftMembership";
  }
  if (window && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(object);
  }
};

export const capturePlansImpressionPromotion = (plans, coupon, publisherName) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "select_promotion",
      current_url: document.title,
      page_path: window.location.href,
      ecommerce: {
        creative_name: coupon["coupon-name"],
        creative_slot: "membershipCoupon",
        promotion_id: coupon["coupon-name"],
        promotion_name: coupon["coupon-code"],
        coupon_percentage: coupon.discount[0],
        items: plans.slice(0, 3).map((plan, ind) => ({
          item_id: plan.id,
          item_name: plan.title,
          affiliation: publisherName === "quint-hindi" ? "HQ-Membership Plans" : "TQ-Membership Plans",
          coupon: (coupon && coupon["coupon-name"]) || "N/A",
          discount:
            coupon && coupon.discount && coupon.discount.length
              ? ((plan.price_cents * 100) / (100 - coupon.discount[ind]) - plan.price_cents) / 100
              : "N/A",
          index: ++ind,
          item_brand: publisherName === "quint-hindi" ? "HQ Membership Plans" : "TQ Membership Plans",
          item_category: "Membership Plan",
          item_list_id: publisherName === "quint-hindi" ? "hq_membership_plans" : "tq_membership_plans",
          item_list_name: publisherName === "quint-hindi" ? "HQ Membership Plans" : "TQ Membership Plans",
          item_variant: "membership plan",
          location_id: "N/A",
          price: plan.price_cents / 100,
          quantity: 1,
        })),
      },
    });
  }
};

export function captureCheckout(plan, eventName) {
  if (window && window.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: eventName,
      ecommerce: {
        checkout: {
          actionField: {},
          products: [
            {
              name: plan.name || plan.title,
              id: plan.id,
              price: plan.price_cents / 100,
              brand: "TheQuint",
              category: plan.description,
              variant: "Membership",
              quantity: 1,
            },
          ],
        },
      },
    });
  }
}

export function addSignUpLoginToDL(event, userObj) {
  const mobileNumber = getMobileNumber(userObj);
  const name = userObj["first-name"] || userObj.name;

  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event,
      userName: name,
      userEmail: userObj.email,
      userMobileNumber: mobileNumber,
    });
}

export const addSignUpLoginToDLNew = (event, userObj, path, logInFrom) => {
  const mobileNumber = getMobileNumber(userObj);
  const name = userObj["first-name"] || userObj.name;
  const result = getSectionAndSubsectionBySlug(path.substr(1));
  const [section = "", subSection = "", subSubSection = ""] = result || [];

  const object = {
    event: event || "N/A",
    userName: name || "N/A",
    userEmail: userObj.email || "N/A",
    userMobileNumber: mobileNumber || "N/A",
    section: section || "N/A",
    subSection: subSection || "N/A",
    sub_sub_section: subSubSection || "N/A",
    pageTitle: document.title,
    userLoginMedium:
      (userObj && userObj["verification-status"] && userObj["verification-status"].split("-")[1]) || "email",
    user_id: userObj.id,
  };
  if (event === "login") {
    object.loginFrom = logInFrom;
  } else object.signUpFrom = logInFrom;

  if (window && window.dataLayer) {
    window.dataLayer.push(object);
  }
};

export const captureBottomNavigationClick = (name, member, isActivePlan, activePlanId) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: "bottom_navigation_click",
      bottom_navigation_cta: name || " ",
      usertype: member && member.email ? "Logged In" : "Logged Out",
      subscribertype: isActivePlan && isActivePlan ? "Subscriber" : "Non-Subscriber",
      userID: member && member.id && member.id,
      subscriptionID: activePlanId || null,
    });
  }
};

export const captureSuccessfullPayment = (name, method, amount, memberId, activePlanId) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: "subscription_purchase_successfull",
      subscription_entry: name && name,
      payment_method: method && method,
      payment_frequency: "pay_once",
      subscription_category: "q_insider",
      subscription_plan: amount && amount === 500 ? "3 months" : amount === 1800 ? "12 months" : "12 months (premium)",
      subscription_amount: amount && amount,
      usertype: "Logged In",
      subscribertype: "Subscriber",
      userID: memberId && memberId,
      subscriptionID: activePlanId || null,
    });
  }
};

export const captureCouponTracking = (name, planId, transactionId, amount, couponName, discount) => {
  let discountValue = 0;
  if (discount !== 0) {
    const originalValue = amount / (1 - discount / 100);
    discountValue = originalValue - amount;
  }

  const data = {
    ecommerce: {
      purchase: {
        actionField: {
          id: transactionId,
          affiliation: "membership",
          revenue: amount,
          tax: "",
          shipping: "",
          coupon: couponName,
          coupon_discount_value: discountValue,
          coupon_discount_percentage: discount,
        },
        products: [
          {
            name: name,
            id: planId,
            price: amount,
            brand: "TQ - Membership Plan",
            category: "Membership",
            quantity: 1,
            coupon: couponName,
            coupon_discount_value: discountValue,
            coupon_discount_percentage: discount,
          },
        ],
      },
    },
  };
  console.log("Coupon traking DL push", data);
  if (window && window.dataLayer) {
    window.dataLayer.push({
      ecommerce: {
        purchase: {
          actionField: {
            id: transactionId,
            affiliation: "membership",
            revenue: amount,
            tax: "",
            shipping: "",
            coupon: couponName,
            coupon_discount_value: discountValue,
            coupon_discount_percentage: discount,
          },
          products: [
            {
              name: name,
              id: planId,
              price: amount,
              brand: "TQ - Membership Plan",
              category: "Membership",
              quantity: 1,
              coupon: couponName,
              coupon_discount_value: discountValue,
              coupon_discount_percentage: discount,
            },
          ],
        },
      },
    });
  }
};

export const captureSpecialProjectSuccessfullPayment = (entry, name, amount, member, isActivePlan, activePlanId) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: "spl_project_payment_success",
      spcl_project_entry: entry && entry,
      spcl_project_name: name && name,
      spcl_project_amount: amount && amount,
      usertype: member && member.email ? "Logged In" : "Logged Out",
      subscribertype: isActivePlan && isActivePlan ? "Subscriber" : "Non-Subscriber",
      userID: member && member.id && member.id,
      subscriptionID: activePlanId || null,
    });
  }
};

export const captureQInsidePopup = (action, story, member, isActivePlan, activePlanId) => {
  if (window && Array.isArray(window.dataLayer)) {
    window.dataLayer.push({
      event: "tqi_popup_cta_click",
      user_action: action && action,
      article_name: story ? (story.headline ? story.headline : null) : null,
      article_editor_name: story ? (story["author-name"] ? story["author-name"] : null) : null,
      article_category: story ? (story.sections ? story.sections[0].name : null) : null,
      usertype: member && member.email ? "Logged In" : "Logged Out",
      subscribertype: isActivePlan && isActivePlan ? "Subscriber" : "Non-Subscriber",
      userID: member && member.id && member.id,
      subscriptionID: activePlanId || null,
    });
  }
};

export const captureSupportUsClick = (name, story, member, isActivePlan, activePlanId) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: "tqi_popup_cta_click",
      cta_type: name && name,
      article_name: story ? (story.headline ? story.headline : null) : null,
      article_editor_name: story ? (story["author-name"] ? story["author-name"] : null) : null,
      article_category: story ? (story.sections ? story.sections[0].name : null) : null,
      usertype: member && member.email ? "Logged In" : "Logged Out",
      subscribertype: isActivePlan && isActivePlan ? "Subscriber" : "Non-Subscriber",
      userID: member && member.id && member.id,
      subscriptionID: activePlanId || null,
    });
  }
};

export const captureSpecialProjectBannerClick = (name, member, isActivePlan, activePlanId) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: "spl_project_option_click",
      option_text: name && name,
      banner_location: "special_project_page",
      usertype: member && member.email ? "Logged In" : "Logged Out",
      subscribertype: isActivePlan && isActivePlan ? "Subscriber" : "Non-Subscriber",
      userID: member && member.id && member.id,
      subscriptionID: activePlanId || null,
    });
  }
};

export const captureShowMoreVideosClick = (member, isActivePlan, activePlanId) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: "video_section_click",
      option_text: "show_more",
      usertype: member && member.email ? "Logged In" : "Logged Out",
      subscribertype: isActivePlan && isActivePlan ? "Subscriber" : "Non-Subscriber",
      userID: member && member.id && member.id,
      subscriptionID: activePlanId || null,
    });
  }
};

export const captureSuggestedArticleClick = (text, name, editorName, category, member, isActivePlan, activePlanId) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: "search_article_click",
      search_text: text && text,
      article_name: name && name,
      article_editor_name: editorName && editorName,
      article_category: category && category,
      usertype: member && member.email ? "Logged In" : "Logged Out",
      subscribertype: isActivePlan && isActivePlan ? "Subscriber" : "Non-Subscriber",
      userID: member && member.id && member.id,
      subscriptionID: activePlanId || null,
    });
  }
};

export const captureSectionExpandClick = (section, member, isActivePlan, activePlanId) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: "explore_section_click",
      article_section: section && section,
      usertype: member && member.email ? "Logged In" : "Logged Out",
      subscribertype: isActivePlan && isActivePlan ? "Subscriber" : "Non-Subscriber",
      userID: member && member.id && member.id,
      subscriptionID: activePlanId || null,
    });
  }
};

export const captureGiftViewPromotion = (currentPlan) => {
  const { id, title, price_cents } = currentPlan;
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "view_promotion",
    current_url: document.title,
    page_path: window.location.href,
    ecommerce: {
      creative_name: "GiftMembership",
      creative_slot: "GiftMembership",
      promotion_id: "GiftMembership",
      promotion_name: "GiftMembership",
      items: [
        {
          item_id: id,
          item_name: title,
          affiliation: "TQ-Membership Plans",
          coupon: "", // If coupon is not there, leave it blank
          discount: "", // in Float if above coupon is applied, else leave it blank
          index: 0,
          item_brand: "TQ Membership Plans",
          item_category: "GiftMembership",
          item_list_id: "tq_membership_plans",
          item_list_name: "TQ Membership Plans",
          item_variant: "membership plan",
          location_id: window.location.href,
          price: price_cents / 100,
          quantity: 1,
        },
      ],
    },
  });
};

export const captueGiftPlanPromotion = (currentPlan) => {
  const { id, title, price_cents } = currentPlan;
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "select_promotion",
    current_url: document.title,
    page_path: window.location.href,
    ecommerce: {
      creative_name: "GiftMembership",
      creative_slot: "GiftMembership",
      promotion_id: "GiftMembership",
      promotion_name: "GiftMembership",
      items: [
        {
          item_id: id,
          item_name: title,
          affiliation: "TQ-Membership Plans",
          coupon: "", // If coupon is not there, leave it blank
          discount: 0, // in Float if above coupon is applied, else leave it blank
          index: 0,
          item_brand: "TQ Membership Plans",
          item_category: "GiftMembership",
          item_list_id: "tq_membership_plans",
          item_list_name: "TQ Membership Plans",
          item_variant: "membership plan",
          location_id: window.location.href,
          price: price_cents / 100,
          quantity: 1,
        },
      ],
    },
  });
};
