import React, { useEffect, useState } from "react";
import { func, object } from "prop-types";
import { GiftBoxIconOne, GiftBoxIconTwo, GiftBoxIconThree, GiftBoxIconFour } from "../../atoms/icons/gift-box-icons";
import CrossIcon from "../../atoms/icons/cross-icon";

import "./gift-view.m.css";

const GiftView = ({ closePopup, giftMembership }) => {
  const ShowGiftMessage = () => {
    const GiftIconBoxes = [
      <GiftBoxIconOne key={0} />,
      <GiftBoxIconTwo key={1} />,
      <GiftBoxIconThree key={2} />,
      <GiftBoxIconFour key={3} />,
    ];
    const [currentBox, setCurrentBox] = useState(0);

    useEffect(() => {
      const timer = setTimeout(() => {
        setCurrentBox((prev) => prev + 1);
      }, 500);

      return () => clearTimeout(timer);
    }, [currentBox]);

    useEffect(() => {
      document.documentElement.style.overflowY = "hidden";

      return () => {
        document.documentElement.style.overflowY = "auto";
      };
    }, []);
    return (
      <div styleName={currentBox > 0 ? "gift-box-wrapper gift-open-svg" : "gift-box-wrapper"}>
        {currentBox < 4 ? (
          <div styleName="gift-box">{GiftIconBoxes[currentBox]}</div>
        ) : (
          <div styleName="speech-bubble">
            <p>{giftMembership && giftMembership.GifteeNote}</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div styleName="wrapper">
      <ShowGiftMessage />
      <div styleName="cross-icon" onClick={closePopup}>
        <CrossIcon width={20} height={20} />
      </div>
    </div>
  );
};

GiftView.propTypes = {
  closePopup: func,
  giftMembership: object,
};
export default GiftView;
