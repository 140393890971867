/* eslint-disable max-len */
import React from "react";
import { number } from "prop-types";

const FitHindiColorLogo = ({ width = 80, height = 45 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 500 226.3">
    <g id="Layer_7" data-name="Layer 7">
      <path
        fill="#03c5c4"
        d="m142.88 207.69-65-70.36s-24.75-.5-34.13-1.1C18.12 134.57 7.49 117.28 8.26 93.3c0 0 .22-34 1.14-49.85C10.74 20 22.28 11.78 45.8 13.22A425.51 425.51 0 0 1 98 20.15c14.61 3.09 24.52 10 29.67 29.83 2.54 9.78 7.86 38.64 10.18 51.46 2.61 14.4 1.59 29.76-18 33.32Z"
      />
      <path
        fill="#ffffff"
        d="M250.68 50.06C261.43 33 279.83 21 304.33 25c17.38 2.89 35 18.5 47.77 26.91C367.93 62.38 382 72.48 403 77.23c21.88 4.94 37.53 4.27 53.58-2.26 6.65-2.72 13.25-8.43 16.61-11.92a31.62 31.62 0 0 0 8-14.06c-.06 0-5.34-2.11-4.24-1.75 0 0-1.67 6-10.44 12.2-10.73 7.57-29.78 8.64-50.58 1-21.23-7.77-32.59-14.8-52.62-24.6-24.9-12.11-43.52-19.75-68.31-18.63a77.55 77.55 0 0 0-40.63 13.21c-32.16 20.92-46.3 67.58-56.15 99.87-8.14 26.68-15 50.88-23.44 77.8h23c11.51-35.81 21.55-70.2 33.43-105.9 5.44-16.37 10.07-37.19 19.47-52.13Z"
      />
    </g>
    <path
      fill="#ffffff"
      d="M467.15 92.75c-2.2 6.7-4 18.53-6.13 24.38-3.53-5.84-10.88-9.35-17.84-9.91-30.39-2.42-49.83 19.09-59 45.07-5.23 14.26-7.39 32.94 4.51 45.26 12.15 12.58 28.53 12.81 43.23 8.74 17-4.71 28.46-14.81 36.41-28.61l-6.61-2.42c-4.85 10.18-20.19 24-38 26.24-10.86.63-20.47-3.28-20.43-19.61.22-12.16 4.89-26.85 9.87-37.62 4.74-10.25 16.36-33.43 32.91-30.42 6.69 1.23 12 8.44 12 15.39h23.82c4.14-13 7.61-28.77 11.76-41.72h-250.1L242 92.83Z"
      id="Layer_8"
      data-name="Layer 8"
    />
    <path
      fill="#ffffff"
      d="M366.12 121.34c-15.43-8.44-31.7.36-38.57 5.66a64 64 0 0 0-5.14 4.76l13-41.88L312.24 92 289 168.15c-3.74 5.92-16.33 23.4-29.86 14.41-3-2.17-10.6-8.55-4.94-27.48s19.77-63.63 19.77-63.63l-22.54.84-21 69.78s-6.27 23.75 14.58 31.58c18.61 7 33.94-4.63 39.94-12.36l-7.72 25.26h22l18.54-59.83c5.06-3.51 12.38-7.73 20.51-9.59 15.18-3.5 21.45 12.65 20.61 21.57-3.14 28.32-31.82 31.82-31.82 31.82l-2.41 8.8c36.51 4.45 50.25-34.72 51.7-40.74s5.18-28.81-10.24-37.24Z"
      id="Layer_6"
      data-name="Layer 6"
    />
  </svg>
);

FitHindiColorLogo.propTypes = {
  width: number,
  height: number,
};

export default FitHindiColorLogo;
