import { get, range } from "lodash";

import removeHTMLTags from "../helper/removeHTMLTags";
import { signUpUser, sendTransactionalEmail } from "../helper/api";
import { setCookie, eraseCookie, readCookie } from "../helper/cookies";
import { isPaywalled } from "../helper/story-elements-utils";

import { MONTHS, PAGE_TYPE } from "../../constants";

export const getHeadline = (story) => {
  if (!story) {
    return null;
  }

  return story.headline || get(story, ["alternative", "home", "default", "headline"], null);
};

export const showEmptyHeader = (pageType) => {
  return !["not-available-page", "membership-page"].includes(pageType);
};

export const capitalizeFirstLetters = (str) => {
  const wordsArr = str.split(" ");
  for (let i = 0; i < wordsArr.length; i++) {
    const word = wordsArr[i];
    wordsArr[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }
  return wordsArr.join(" ");
};

export const alternateCollectionName = (collection) => {
  const name = get(collection, ["metadata", "alternative-title"], "");
  if (name && name.length > 0) {
    return name;
  }
  return collection.name;
};

export const getSectionName = (story) => {
  if (!story) {
    return null;
  }

  return get(story, ["sections", "0", "display-name"]) || get(story, ["sections", "0", "name"]);
};

export const getSectionSlug = (story) => get(story, ["sections", "0", "section-url"], "#");

export const getAuthor = (story) => {
  if (!story) {
    return null;
  }

  return get(story, ["authors", "0"], {});
};

export const getAuthors = (story) => {
  const authorList = story.authors || [];

  if (!authorList.length) {
    return authorList; // return empty []
  }

  return authorList.map((c) => ({
    name: c.name,
    slug: `/author/${c.id}/${c.slug}`,
    role: c["contributor-role"] && c["contributor-role"].name,
  }));
};

export const getUserMetaData = (member) => get(member, ["metadata", "userData"], {});

export const getMobileNumber = (member) => {
  const userData = getUserMetaData(member);
  const mobile = userData.mobileNumber || get(member, ["phone-number"], "");
  return mobile;
};

export const isMobileNoPresent = (member) => {
  const mobile = getMobileNumber(member);
  return !!mobile;
};

export const isAuthorNameShown = (story) => {
  const authorBlockList = get(story, ["metadata", "story-attributes", "authorblock"], null);

  // If authorblock contains a value as "show" then show the author name
  return !!(authorBlockList && authorBlockList.length && authorBlockList.includes("show"));
};

export const getStoryLine = (story) => {
  if (!story) {
    return null;
  }

  const value = get(story, ["metadata", "sponsored-by"], null);
  return value;
};

export const getTitleAndCards = (cards, explainerCard = false) => {
  const newCards = [];

  cards.forEach((card) => {
    const titleElement = card["story-elements"].find(({ type }) => type === "title");

    if (titleElement) {
      const elements = [...card["story-elements"]];
      const index = elements.findIndex((i) => i.id === titleElement.id);
      if (index !== -1) elements.splice(index, 1); // remove title from elements

      newCards.push({
        title: titleElement,
        card: {
          ...card,
          "story-elements": elements,
        },
      });
    }
  });
  return newCards;
};

export const getLanguage = (publisher, currentPath) => {
  switch (publisher) {
    case "quint":
      return "en";
    case "quint-hindi":
      return "hi";
    case "fit":
      return currentPath.includes("/hindi") ? "hi" : "en";
    default:
      return "en";
  }
};

export const sections = (page, story) => {
  switch (page.type) {
    case "home":
      return "HOME";
    case "section":
      return page.section_name;
    case "story":
      return story.sections.map((section) => section.name).join(",");
    default:
      return "";
  }
};

// Meant for schemas
export const getStoryWithConfig = (state) => {
  return {
    secondaryMenuItems: get(state, ["qt", "data", "navigationMenu", "secondaryMenuItems"], []),
    audioDuration: get(state, ["qt", "data", "audioDuration"]),
    mp3Url: get(state, ["qt", "data", "mp3Url"]),
    sectionsList: get(state, ["qt", "config", "sections"]),
    currentPath: get(state, ["qt", "currentPath"], ""),
    sectionId: get(state, ["qt", "data", "sectionId"], {}),
    collection: get(state, ["qt", "data", "collection"], {}),
    story: get(state, ["qt", "data", "story"], {}),
    config: get(state, ["qt", "config"], {}),
    menuItems: get(state, ["qt", "data", "navigationMenu", "menuItems"], []),
  };
};

// Meant for Rendering ClaimReview Schema
export const hasWebqoofSection = (sections) => {
  return sections.map((ele) => ele.slug).includes("webqoof") || sections.map((ele) => ele.slug).includes("fit-webqoof");
};

export const getCollectionSlug = (collection, allSections) => {
  let slug = "";

  const section = allSections.find((sec) => sec.slug === collection.slug);

  if (section) {
    // Fetch the Slug of section by searching in allSections array

    if (section && section["parent-id"]) {
      const parentSection = allSections.find((item) => item.id === section["parent-id"]);

      if (parentSection) {
        slug = `/${parentSection.slug}/${section.slug}`;
      }
    } else if (section) {
      slug = section.slug;
    } else if (collection.template === "topic") {
      slug = `/topic/${collection.slug}`;
    } else {
      slug = `/collection/${collection.slug}`;
    }
  } else if (collection.slug.includes("-fit")) {
    slug = `/fit/collection/${collection.slug}`;
  } else if (collection.template === "topic") {
    slug = `/topic/${collection.slug}`;
  } else {
    slug = `/collection/${collection.slug}`;
  }

  return slug;
};

export const resizeIframes = () => {
  const iframes = document.querySelectorAll(".story-element-jsembed iframe");
  if (iframes.length) {
    iframes.forEach((i) => {
      window.iFrameResize({ checkOrigin: false }, i);
    });
  }
};

export const isMobileWeb = () => {
  const ua = (window.navigator.userAgent || window.navigator.vendor || window.opera).toString().toLowerCase();
  return /(android|ipad|iphone)/.test(ua) ? "mWEB" : "WEB";
};

export const getArticleBodyFromStory = (story) => {
  let accumulator = "";
  story.cards.forEach((card, index) => {
    const elementToShow = card["story-elements"];
    elementToShow.forEach((storyElement, ind) => {
      if (storyElement.type === "text") {
        accumulator += removeHTMLTags(storyElement.text);
      }
    });
  });

  return accumulator;
};

export const getPaywalledContentFromStory = (story) => {
  let accumulator = "";
  story.cards.forEach((card, index) => {
    const elementToShow = card["story-elements"];
    elementToShow.forEach((storyElement, ind) => {
      const leftOut = index === 0 ? (story["access-level-value"] === 500 ? ind === 0 || ind === 1 : ind === 0) : false;
      if (storyElement.type === "text" && !leftOut) {
        accumulator += removeHTMLTags(storyElement.text);
      }
    });
  });

  return accumulator;
};

export const getSeobySectionId = (sectionId, config) => {
  return get(config, ["seo-metadata"], []).filter((seoMetadata) => seoMetadata["owner-id"] === sectionId);
};

export const getPageTitle = (data, page, config) => {
  if (page === PAGE_TYPE.STORY_PAGE) {
    return get(data, ["story", "seo", "meta-title"], data.title);
  } else if (page === PAGE_TYPE.SECTION_PAGE) {
    const filteredSeoConfig = getSeobySectionId(get(data, ["sectionId"]), config);
    const altTitle = get(config, ["publisher-settings", "title"], "The Quint");

    return get(filteredSeoConfig, [0, "data", "page-title"], altTitle);
  } else if (page === PAGE_TYPE.AUTHOR_PAGE) {
    const author = get(data, ["author", "name"], "");

    return `${author} on The Quint`;
  }
};

export const getSectionAndSubsectionBySlug = (slug) => {
  if (!slug) {
    return;
  }

  slug = slug.split("/");
  slug.pop();
  return slug;
};

export const getAllSectionsStories = (sectionName, collection, storiesArray, limit, stories = []) => {
  if (sectionName === "All Sections") {
    if (collection.template !== "external-collection") {
      if (collection.id === 1022 || collection.id === 1028 || collection.id === 1030 || collection.id === 8413) {
        stories = storiesArray.map((item) => item.story);
      } else {
        stories = storiesArray.filter((item) => item.type === "collection")[0].items.map((item) => item.story);
      }
    }
  } else {
    stories = storiesArray.filter((item) => item.type === "story").map((item) => item.story);
  }
  if (limit) {
    return stories.slice(0, 6);
  } else {
    return stories;
  }
};

export const getPushNotificationStatus = () => {
  const Notification = window.Notification || window.mozNotification || window.webkitNotification;

  if (Notification) {
    return Notification.permission === "granted" ? "Notification - Yes" : "Notification - No";
  } else {
    console.error("Notification API not supported!");
    return "Notification - No";
  }
};

export const sortItems = (a, b) => {
  if (
    (a.status === "active" && b.status !== "active") ||
    (a.status === "pending" && b.status !== "pending" && b.status !== "active")
  )
    return -1;
  else if (
    (a.status !== "active" && b.status === "active") ||
    (a.status !== "active" && a.status !== "pending" && b.status === "pending")
  )
    return 1;
  else return 0;
};

export const generateSitemapLinks = (forYear, forMonth, hasSpecificYear, appUrl) => {
  const results = [];
  const years = hasSpecificYear ? [hasSpecificYear] : range(2016, new Date().getFullYear() + 1);

  if (forYear && forMonth) {
    years.map((year) => {
      MONTHS.map((month) => {
        results.push({
          loc: `${appUrl}/sitemap/${year}-${month}.xml`,
          year,
          month,
        });
      });
    });
    return results;
  } else if (!forMonth) {
    years.map((year) => {
      MONTHS.map((month) => {
        results.push({
          loc: `${appUrl}/sitemap/videos/${year}-${month}.xml`,
          year,
          month,
        });
      });
    });
    return results;
  }
};

export const generatePhotoSitemapLinks = (forYear, forMonth, hasSpecificYear, appUrl) => {
  const results = [];
  const years = hasSpecificYear ? [hasSpecificYear] : range(2016, new Date().getFullYear() + 1);

  if (forYear && forMonth) {
    years.map((year) => {
      MONTHS.map((month) => {
        results.push({
          loc: `${appUrl}/sitemap/${year}-${month}.xml`,
          year,
          month,
        });
      });
    });
    return results;
  } else if (!forMonth) {
    years.map((year) => {
      MONTHS.map((month) => {
        results.push({
          loc: `${appUrl}/sitemap/photos/${year}-${month}.xml`,
          year,
          month,
        });
      });
    });
    return results;
  }
};

export const isTNMStory = (story) => {
  const allAuthors = story.authors || [];
  const isTNMAuthor = allAuthors.map((a) => a.name).includes("The News Minute");
  const hasTNMAttribute = get(story.metadata, ["story-attributes", "thenewsminute", "0"], "False") === "True";

  if (isTNMAuthor || hasTNMAttribute) {
    return true;
  }
  return false;
};

export async function checkIfAlreadyMemeber(email, name = "") {
  try {
    return await signUpUser({
      email,
      username: name,
    });
  } catch (error) {
    return JSON.parse(error.message)?.error?.message;
  }
}

export const facebookPixel = (event, data = null) => {
  const options = {
    autoConfig: true,
    debug: false,
  };

  return import("react-facebook-pixel")
    .then((module) => module.default)
    .then((ReactPixel) => {
      ReactPixel.init("1236538276434329", options);
      if (!data) {
        ReactPixel.track(event);
      } else {
        ReactPixel.trackCustom(event, data);
      }
    });
};

export const moengageTracking = (type, data) => {
  if (type === "purchase" && window.moengageEventTracking) {
    window.moengageEventTracking(data);
  } else {
    const name = data["first-name"] || data.name;
    const mobileNumber = getMobileNumber(data);

    const firstName = name.split(" ")[0];
    const lastName = name.split(" ").slice(1).join(" ");

    const memberData = {
      firstName,
      lastName,
      email: data.email,
      mobileNumber,
      fullName: name,
    };
    if (window.moengageUserTracking) {
      window.moengageUserTracking(memberData);
    }
  }
};

export const addIdToPromotionalMessageStoryElement = (element) => {
  if (element.metadata["promotional-message"]) {
    const position = element.text.indexOf(">");

    if (position >= 0) {
      element.text = element.text.slice(0, position) + " id='promotional-message'" + element.text.slice(position);
      return element;
    }
  }
};

export const postStripePaymentFailure = (publisherName) => {
  const paymentFlag = readCookie("initStripe") === "true";
  if (window.localStorage.stripeData && paymentFlag) {
    eraseCookie("initStripe");
    const res = JSON.parse(window.localStorage.stripeData || "{}");
    const options = {
      userEmail: res.email,
      userName: res["first-name"] || res.name,
      type: "qinsider-failure",
      publisherName: publisherName,
    };
    sendTransactionalEmail(options);
  }
};

export const memberShipProjectType = (publisherName) =>
  publisherName === "quint-hindi" ? "HQ - Membership Plans" : "TQ - Membership Plans";

export const isEmptyObject = (obj) => {
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};

export const isBlackListerStory = (slug) => {
  if (slug && slug.includes("politics")) return false;
  const blacklist = ["garena", "wordle", "lottery", "brandstudio", "teer", "1676411", "1645383", "1641157"];
  const regex = new RegExp("\\b(" + blacklist.join("|") + ")\\b", "gi");
  const isBlackListed = regex.test(slug);
  return isBlackListed;
};

export const headlineToUrl = (headline) => {
  const headlineTrimed = headline.trim();
  const urlHash = headlineTrimed.replace(/[\u200B"' ‘’]/g, "");
  return urlHash;
};

export const isDateOlderThanCutOff = (timestamp, cutoffDate) => {
  return timestamp < cutoffDate;
};

export const filterLimitedAccessStory = (collectionSlug, items) => {
  if (collectionSlug === "members-only") {
    return items.filter((item) => {
      if (item.type === "story") {
        return isPaywalled(item.story);
      }
    });
  }
  return items;
};

export const initStripePayment = (stripeData) => {
  setCookie("initStripe", "true", 1000);
  window.localStorage.stripeData = JSON.stringify(stripeData);
  window.localStorage.paymentData = JSON.stringify(stripeData);
};
