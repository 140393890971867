import React, { useState, useEffect } from "react";
import { object, string, number } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";

import Facebook from "../../atoms/icons/social-share/facebook";
import Twitter from "../../atoms/icons/social-share/twitter";
import Whatsapp from "../../atoms/icons/social-share/whatsapp";
import Telegram from "../../atoms/icons/social-share/telegram";
import Youtube from "../../atoms/icons/social-share/youtube";

import { captureBottomNavigationClick } from "../../helper/datalayer-utils";

import "./social-icons.m.css";

const SocialIcons = ({ member, isActivePlan, activePlanId, socialLinks, telegramUrl }) => {
  const [showIcons, setShowIcons] = useState(false);
  if (!socialLinks) {
    return null;
  }

  useEffect(() => {
    setTimeout(() => {
      setShowIcons(true);
    }, 3500);
  }, []);
  const list = [
    {
      Type: <Facebook isShareRequired={false} />,
      url: socialLinks["facebook-url"] || "",
      name: "Facebook",
      ariaLabel: "The Quint Facebook page",
    },
    {
      Type: <Twitter isShareRequired={false} width={15} height={15} />,
      url: socialLinks["twitter-url"] || "",
      name: "Twitter",
      ariaLabel: "The Quint Twitter account",
    },
    {
      Type: <Whatsapp isShareRequired={false} />,
      url: socialLinks["whatsapp-url"] || "",
      name: "Whatsapp",
      ariaLabel: "The Quint Whatsapp group",
    },
    {
      Type: <Telegram isShareRequired={false} />,
      url: socialLinks["telegram-url"] || telegramUrl,
      name: "Telegram",
      ariaLabel: "The Quint Telegram group",
    },
    {
      Type: <Youtube isShareRequired={false} />,
      url: socialLinks["youtube-url"] || "",
      name: "Youtube",
      ariaLabel: "The Quint Youtube page",
    },
  ];

  return (
    <div styleName="wrapper">
      {showIcons && (
        <>
          {list.map(({ url, Type, name, ariaLabel }, index) => (
            <a
              href={url}
              key={index}
              aria-label={ariaLabel}
              styleName="social-link-item"
              className="social-link-item"
              target="_blank"
              rel="nofollow"
              onClick={() => {
                setTimeout(captureBottomNavigationClick(name, member && member, isActivePlan, activePlanId), 1000);
              }}
            >
              {Type}
            </a>
          ))}
        </>
      )}
    </div>
  );
};

SocialIcons.propTypes = {
  member: object,
  isActivePlan: number,
  activePlanId: number,
  socialLinks: object,
  telegramUrl: string,
};

const mapStateToProps = (state) => ({
  member: state.member,
  isActivePlan: get(state, ["userReducer", "isActivePlan"]),
  activePlanId: get(state, ["userReducer", "activePlanId"], null),
  socialLinks: get(state, ["qt", "config", "social-links"], null),
  telegramUrl: get(state, ["qt", "config", "publisher-attributes", "social_links", "telegram_Url"], ""),
});

export default connect(mapStateToProps, null)(SocialIcons);
