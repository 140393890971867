/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { string, object, func, bool } from "prop-types";
import get from "lodash/get";
import { connect } from "react-redux";
import { throttle } from "lodash";
import { setBottomStickyAd } from "../../helper/actions";
import { AdSlot, AdFree } from "../../rows/ad-free";
import { isPaywalled, isFreemium } from "../../helper/story-elements-utils";
import "./sticky-banner.m.css";

const StickyBanner = ({ story = {}, section = {}, pageType, setBottomStickyAd, bottomStickyAd }) => {
  const [freemium] = isFreemium(story);
  if (freemium && !isPaywalled(story)) {
    return null;
  }

  const [displayAd, setDisplayAd] = useState(true);
  const renderAdSlot = (id, type) => {
    switch (pageType) {
      case "story-page":
        return <AdSlot id={id} type={type} story={story} />;
      case "section-page":
        return <AdSlot id={id} type={type} section={section} />;
      default:
        return <AdSlot id={id} type={type} />;
    }
  };
  const closeClickHandler = () => {
    setDisplayAd(false);
    setBottomStickyAd(false);
  };
  const prevScrollY = useRef(0);

  useEffect(() => {
    function handleScroll() {
      const scrollY = window.scrollY;
      if (scrollY > prevScrollY.current) {
        setDisplayAd(false);
      } else {
        setDisplayAd(true);
      }
      prevScrollY.current = scrollY;
    }
    window.addEventListener("scroll", throttle(handleScroll, 50));
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    bottomStickyAd && (
      <AdFree>
        <div styleName="wrapper">
          <div styleName={!displayAd ? "hide-ad" : "banner mobile"} className="bottomSticky">
            <div styleName="cross-btn-mobile" onClick={closeClickHandler}>
              &#xd7;
            </div>
            {renderAdSlot("stickey", "mobile")}
          </div>
          <div styleName={!displayAd ? "hide-ad" : "banner desktop"} className="bottomSticky">
            <div styleName="cross-btn" onClick={closeClickHandler}>
              &#xd7;
            </div>
            {renderAdSlot("stickey", "desktop")}
          </div>
        </div>
      </AdFree>
    )
  );
};

StickyBanner.propTypes = {
  pageType: string,
  story: object,
  section: object,
  setBottomStickyAd: func,
  bottomStickyAd: bool,
};

const mapStateToProps = (state) => ({
  pageType: get(state, ["qt", "pageType"]),
  bottomStickyAd: get(state, ["userReducer", "bottomStickyAd"], true),
});

const mapDispatchToProps = (dispatch) => ({
  setBottomStickyAd: (value) => dispatch(setBottomStickyAd(value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(StickyBanner);
