import React from "react";

import { ResponsiveImage } from "@quintype/components";

const Telegram = () => (
  <ResponsiveImage
    slug="thequint/2024-07/25a303e8-4395-48d9-8191-1a8bf511d442/telegram.png"
    type="image/webp"
    alt="Telegram"
    aspectRatio={[1, 1]}
    defaultWidth={20}
    eager={false}
    imgParams={{ auto: ["format", "compress"] }}
    imageCDN="images.thequint.com"
  />
);

export default Telegram;
