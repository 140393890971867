import { format } from "date-fns";

// function getTimezoneName(timestamp) {
//   if (!timestamp) {
//     return;
//   }

//   return new Date(timestamp).toLocaleTimeString("en-us", { timeZoneName: "short" }).split(" ")[2].slice(0, 3) || "IST";
// }

export function dateUtils(date, publisherName) {
  if (!date) return;

  // const publishedAt = formatDistance(timestamp, new Date(), {
  //   locale: publisherName === "quint-hindi" ? hi : null,
  // });

  const formattedDate = `${format(date, "dd MMM yyyy, hh:mm a")}`;
  // const duration = differenceInHours(new Date(), timestamp);
  /**
   * commenting below line,Need to Implement once designer confirms
   */
  // return duration > 25 ? `${formattedDate} ${getTimezoneName(timestamp)}` : `${publishedAt} ago`
  return `${formattedDate} IST`;
}

export function schemaDate(timestamp) {
  if (!timestamp) return;

  const date = new Date(timestamp).setHours(new Date(timestamp).getHours() + 5, new Date(timestamp).getMinutes() + 30);
  return format(date, "yyyy-MM-dd'T'HH:mm:ss") + "+05:30";
}

/* Need below fragments for analytics tracking */
export const gethhmm = (timestamp) => {
  if (!timestamp) return;

  return new Date(timestamp).toLocaleTimeString("en-Us", {
    timeZone: "Asia/Kolkata",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const getddMMyyyy = (timestamp) => {
  if (!timestamp) return;

  return `${format(timestamp, "dd MMM yyyy, hh:mm a").slice(0, 12)}`;
};

export const handleTimeFormat = (timestamp) => {
  if (!timestamp) return;

  const finalDate = getddMMyyyy(timestamp) + " " + gethhmm(timestamp);

  return `${finalDate} IST`;
};
/* End */

export const formatDate = (timestamp) => {
  if (!timestamp) {
    return;
  }
  // add 5h30min to maintain CSR & SSR Time to be equal
  timestamp += +330 * 60 * 1000;
  return `${format(timestamp, "dd MMM yyyy, hh:mm a")} IST`;
};

export const getStoryDate = (publishedTime) => {
  const result = new Date(publishedTime).toLocaleString("en-US", {
    day: "2-digit",
    year: "numeric",
    month: "short",
    timeZone: "Asia/Calcutta",
    hour: "numeric",
    minute: "numeric",
  });
  const [month, date, ...yearAndTime] = result.split(" ");

  return `${date.replace(",", "")} ${month} ${yearAndTime.join(" ")} IST`;
};
