/* eslint-disable max-len */
import React from "react";
import { number } from "prop-types";

const FitHindiLogo = ({ width = 65, height = 37 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 42" width={width} height={height} className="fit-hindi-icon">
    <defs />
    <g data-name="Group 30513">
      <path fill="rgba(255,255,255,0)" d="M0 0h75v42H0z" data-name="Rectangle 1874" />
      <g data-name="FIT Hindi">
        <path
          fill="#ffffff"
          d="M29 41l-3-4-9-11h-7a7 7 0 01-7-7 58 58 0 010-6V7a11 11 0 011-2 5 5 0 014-4 26 26 0 016 0l7 1a7 7 0 016 6l2 8a25 25 0 010 5 4 4 0 01-3 5l3 14v1z"
          data-name="Path 133512"
        />
        <path
          fill="#ffffff"
          d="M47 24a6 6 0 01-2 1 4 4 0 01-5-4v-5l-1-1-1 1v11l-1 1h-1V16l-1-1h-2v-1l1-1h1a4 4 0 01-1-4 4 4 0 014-3 12 12 0 016 1 21 21 0 015 3 1 1 0 010 2 1 1 0 01-2 0c-2-2-4-4-7-4a7 7 0 00-2 0 3 3 0 00-2 4 4 4 0 001 1 2 2 0 001 0h33l1 1-2 1h-2v4h-4a3 3 0 00-2 1 3 3 0 00-1 3 3 3 0 002 2 6 6 0 004 0 2 2 0 001-2h2c1-1 1-1 1 1a4 4 0 01-2 3c-2 1-6 1-9-1a5 5 0 01-1-3 7 7 0 010-2 4 4 0 013-3 12 12 0 013-1h1v-2h-1-15l-1 1v1l1 1a7 7 0 014 0 4 4 0 014 4 4 4 0 01-5 5l-1-1 1-1a3 3 0 002-4 2 2 0 00-1-1 4 4 0 00-4 0 1 1 0 00-1 1v7a10 10 0 00-1 0h-1v-4zm0-6v-3h-5v5a2 2 0 001 2 3 3 0 003 0 2 2 0 001-1v-3z"
          data-name="Path 133513"
        />
      </g>
    </g>
  </svg>
);

FitHindiLogo.propTypes = {
  width: number,
  height: number,
};

export default FitHindiLogo;
