import React from "react";

import "./login-shimmer.m.css";

function LoginShimmer() {
  return (
    <div styleName="shimmer-wrapper">
      <div styleName="shimmer"></div>
      <div styleName="shimmer"></div>
      <div styleName="shimmer"></div>
      <div styleName="shimmer"></div>
    </div>
  );
}

export default LoginShimmer;
