import React, { useState, useEffect } from "react";
import { bool, array, object, func, string } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";

import CrossIcon from "../../atoms/icons/cross-icon";
import translate from "../../translate";
import { setCookie, readCookie } from "../../helper/cookies";

import "./breaking-news.m.css";

const BreakingNewsView = ({ breakingNewsLoaded, breakingNews, config, navigateToPage, pageType }) => {
  const publisherName = get(config, ["publisher-attributes", "name"]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (breakingNews.length) {
      const [first] = breakingNews;
      const minutes = Math.round((Date.now() - first["last-published-at"]) / 1000 / 60);
      const enableNews = readCookie("breaking-news-limit");

      // Show breaking news upto 30 Min from its published time
      if (!enableNews && minutes < 30 && document.getElementById(`BN-${first.id}`)) {
        setIsOpen(true);
      }
    }
  }, [breakingNews]);

  const onCloseClick = () => {
    setIsOpen(false);
    setCookie("breaking-news-limit", "true", 900);
  };

  const handleClick = () => {
    setIsOpen(false);
    navigateToPage(`/${linkedStorySlug}`);
  };

  if (breakingNewsLoaded && breakingNews.length === 0) {
    return <span />;
  }

  if (
    !breakingNews.length ||
    pageType === "membership-page" ||
    pageType === "campaign-page" ||
    pageType === "hindi-campaign-page"
  ) {
    return <span />;
  }

  const [firstItem] = breakingNews;
  const linkedStorySlug = get(firstItem, ["metadata", "linked-story-slug"], null);

  const getHeadlineView = (item) => <div styleName="message">{item.headline}</div>;

  return (
    <div id={`BN-${firstItem.id}`}>
      {isOpen ? (
        <div styleName={`wrapper ${isOpen ? "" : "close-wrapper"}`}>
          <span styleName="title">{translate(publisherName, "breaking_news")}</span>
          {linkedStorySlug ? (
            <div styleName="news-link" onClick={handleClick}>
              {getHeadlineView(firstItem)}
            </div>
          ) : (
            getHeadlineView(firstItem)
          )}

          <div styleName="cross-icon" onClick={onCloseClick}>
            <CrossIcon width={22} height={22} />
          </div>
        </div>
      ) : null}
    </div>
  );
};

BreakingNewsView.propTypes = {
  breakingNewsLoaded: bool,
  breakingNews: array,
  config: object,
  navigateToPage: func,
  pageType: string,
};

const mapStateToProps = (state) => ({
  pageType: get(state, ["qt", "pageType"], null),
});

const mapDispatchToProps = (dispatch) => ({
  navigateToPage: (url) => global.app.navigateToPage(dispatch, url),
});

export default connect(mapStateToProps, mapDispatchToProps)(BreakingNewsView);
