import React from "react";
import { string } from "prop-types";

const MobileProfileIcon = ({ height = "52", width = "52", minX = "0", minY = "0", x = "52", y = "52" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsxink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox={`${minX} ${minY} ${x} ${y}`}
    >
      <defs>
        <filter id="Path_133482" x="0" y="0" width="52" height="52" filterUnits="userSpaceOnUse">
          <feOffset input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Group_31432" data-name="Group 31432" transform="translate(-302 -3)">
        <g id="Group_31331" data-name="Group 31331" transform="translate(308.16 11.272)">
          <rect
            id="Rectangle_1"
            data-name="Rectangle 1"
            width="40"
            height="40"
            rx="20"
            transform="translate(-0.16 -0.272)"
            fill="#f5f5f5"
          />
        </g>
        <g id="Group_31431" data-name="Group 31431">
          <g transform="matrix(1, 0, 0, 1, 302, 5)" filter="url(#Path_133482)">
            <g id="Path_133482-2" data-name="Path 133482" transform="translate(9 9)" fill="#bababa">
              <path
                d="M 17 33.5 C 12.59269046783447 33.5 8.449179649353027 31.78370094299316 5.33273983001709 28.66725921630859 C 2.216300010681152 25.55081939697266 0.5 21.40731048583984 0.5 17 C 0.5 12.59269046783447 2.216300010681152 8.449179649353027 5.33273983001709 5.33273983001709 C 8.449179649353027 2.216300010681152 12.59269046783447 0.5 17 0.5 C 21.40731048583984 0.5 25.55081939697266 2.216300010681152 28.66725921630859 5.33273983001709 C 31.78370094299316 8.449179649353027 33.5 12.59269046783447 33.5 17 C 33.5 21.40731048583984 31.78370094299316 25.55081939697266 28.66725921630859 28.66725921630859 C 25.55081939697266 31.78370094299316 21.40731048583984 33.5 17 33.5 Z"
                stroke="none"
              />
              <path
                d="M 17 1 C 12.72624969482422 1 8.708290100097656 2.664289474487305 5.686290740966797 5.686290740966797 C 2.664289474487305 8.708290100097656 1 12.72624969482422 1 17 C 1 21.27375030517578 2.664289474487305 25.29170989990234 5.686290740966797 28.3137092590332 C 8.708290100097656 31.3357105255127 12.72624969482422 33 17 33 C 21.27375030517578 33 25.29170989990234 31.3357105255127 28.3137092590332 28.3137092590332 C 31.3357105255127 25.29170989990234 33 21.27375030517578 33 17 C 33 12.72624969482422 31.3357105255127 8.708290100097656 28.3137092590332 5.686290740966797 C 25.29170989990234 2.664289474487305 21.27375030517578 1 17 1 M 17 0 C 26.38883972167969 0 34 7.611160278320312 34 17 C 34 26.38883972167969 26.38883972167969 34 17 34 C 7.611160278320312 34 0 26.38883972167969 0 17 C 0 7.611160278320312 7.611160278320312 0 17 0 Z"
                stroke="none"
                fill="#bababa"
              />
            </g>
          </g>
          <path
            id="Intersection_1"
            data-name="Intersection 1"
            d="M-8932.28-1765a16.984,16.984,0,0,1-13.84-7.528c.644-2.353,1.724-4.482,3.527-5.233,4.762-1.967,6.486,1.428,10.693,1.455h.051c4.238,0,5.959-3.436,10.74-1.46,1.733.717,2.792,2.69,3.44,4.915a16.986,16.986,0,0,1-14.06,7.851Z"
            transform="translate(9259.999 1813)"
            fill="#fff"
          />
          <path
            id="Path_133481"
            data-name="Path 133481"
            d="M6,0A6,6,0,1,1,0,6,6,6,0,0,1,6,0Z"
            transform="translate(322 21)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

MobileProfileIcon.propTypes = {
  height: string,
  width: string,
  minX: string,
  minY: string,
  x: string,
  y: string,
};

export default MobileProfileIcon;
