import React, { useEffect } from "react";
import { func, node } from "prop-types";

import "./popupModal.m.css";

const PopupModal = ({ closePopup, children }) => {
  const handleKeyUp = (e) => {
    if (e.keyCode === 27) {
      e.preventDefault();
      closePopup();
    }
  };

  useEffect(() => {
    window.addEventListener("keyup", handleKeyUp, false);
    document.body.style.overflowY = "hidden";

    return () => {
      document.body.style.overflowY = "auto";
      window.removeEventListener("keyup", handleKeyUp, false);
    };
  });

  return (
    <React.Fragment>
      <div styleName="modal-wrapper" onClick={closePopup} />
      <div styleName="modal">{children}</div>
    </React.Fragment>
  );
};

PopupModal.propTypes = {
  closePopup: func,
  children: node,
};

export default PopupModal;
