import { createStore } from "redux";

const settingIntialState = {
  isDarkTheme: false,
  recentSearchList: [],
  sectionId: null,
};

function settingsReducer(state = settingIntialState, action) {
  switch (action.type) {
    case "TOGGLE_THEME":
      return {
        ...state,
        isDarkTheme: action.payload,
      };

    case "RECENT_SEARCH": {
      return {
        ...state,
        recentSearchList: action.payload,
      };
    }

    case "SECTION_ID": {
      return {
        ...state,
        sectionId: action.payload,
      };
    }

    default:
      return state;
  }
}

const storyReducerState = {
  wruData: {},
  fitData: {},
  showCommentWidget: {},
};

const storyReducer = (state = storyReducerState, action) => {
  switch (action.type) {
    case "STORY_WRU_DATA":
      return {
        ...state,
        wruData: {
          ...state.wruData,
          [action.storyId]: action.payload,
        },
      };
    case "STORY_WRU_FIT_DATA":
      return {
        ...state,
        fitData: {
          ...state.fitData,
          [action.storyId]: action.payload,
        },
      };
    case "TOGGLE_COMMENTS":
      return {
        ...state,
        showCommentWidget: {
          ...state.showCommentWidget,
          [action.storyId]: action.payload,
        },
      };
    default:
      return state;
  }
};

const userReducerState = {
  myPlans: [],
  isActivePlan: 0,
  isExistingPlans: [],
  removeAdDelay: "",
  isPlansLoaded: false,
  showLoginWindow: false,
  makePaymentFlag: false,
  postLoginPayment: false,
  paymentSuccess: false,
  isFloatingShareMenuOpen: false,
  logInFrom: "",
  audioBotLoad: false,
  audioBotPlay: false,
  audioBotResume: false,
  bottomStickyAd: true,
  storyLiked: false,
  likeCount: 0,
  voiceBotFlag: false,
  voiceBotEnable: false,
  textTheme: "Medium",
  giftMembership: null,
};

const userReducer = (state = userReducerState, action) => {
  switch (action.type) {
    case "SET_USER_PLANS": {
      const isExistingPlans = action.plans.subscriptions.filter(
        (plan) => (plan && plan.subscription_type === "standard") || (plan && plan.subscription_type === "campaign")
      );
      const isActivePlans = action.plans.subscriptions.filter(
        (plan) =>
          (plan &&
            (plan.status === "active" || plan.status === "pending") &&
            !plan.expired &&
            plan.subscription_type === "standard") ||
          (plan && !plan.cancelled && !plan.expired && plan.subscription_type === "campaign")
      );
      const giftPlans =
        isActivePlans &&
        isActivePlans.filter((plan) => {
          return plan.gifter !== null;
        });
      return {
        ...state,
        myPlans: action.plans.subscriptions || [],
        isActivePlan: isActivePlans.length,
        isExistingPlans: isExistingPlans,
        activePlanId: isActivePlans && isActivePlans[0] && isActivePlans[0].id,
        isPlansLoaded: true,
        giftMembership: giftPlans && giftPlans[0] && giftPlans[0].metadata,
      };
    }
    case "SET_LOGIN_WINDOW":
      return {
        ...state,
        showLoginWindow: action.payload,
        logInFrom: action.logInFrom,
      };
    case "SET_POST_LOGIN_PAYMENT":
      return {
        ...state,
        showLoginWindow: true,
        postLoginPayment: action.payload,
        logInFrom: action.logInFrom,
      };
    case "SET_LOGIN_UPDATE":
      return {
        ...state,
        makePaymentFlag: action.payload,
      };
    case "AD_DELAY":
      return {
        ...state,
        removeAdDelay: action.payload,
      };
    case "SET_LOGOUT_STATE":
      return {
        ...state,
        isActivePlan: 0,
        myPlans: [],
        isPlansLoaded: false,
        makePaymentFlag: false,
        showLoginWindow: false,
      };
    case "SET_PAYMENT_SUCCESS":
      return {
        ...state,
        paymentSuccess: action.payload,
      };
    case "TOGGLE_FLOATING_MENU":
      return {
        ...state,
        isFloatingShareMenuOpen: action.payload,
      };
    case "SET_AUDIO_BOT_LOAD":
      return {
        ...state,
        audioBotLoad: action.payload,
      };
    case "SET_AUDIO_PLAY":
      return {
        ...state,
        audioBotPlay: action.payload,
      };
    case "SET_AUDIO_RESUME":
      return {
        ...state,
        audioBotResume: action.payload,
      };
    case "SET_BOTTOM_STICKY_AD":
      return {
        ...state,
        bottomStickyAd: action.payload,
      };
    case "LIKE_STORY":
      return {
        ...state,
        storyLiked: action.payload,
      };
    case "LIKE_STORY_COUNT":
      return {
        ...state,
        likeCount: action.payload,
      };
    case "SET_VOICE_BOT_FLAG":
      return {
        ...state,
        voiceBotFlag: action.payload,
      };
    case "SET_VOICE_BOT_ENABLE":
      return {
        ...state,
        voiceBotEnable: action.payload,
      };
    case "SET_TEXT_THEME":
      return {
        ...state,
        textTheme: action.payload,
      };
    default:
      return state;
  }
};

const campaignReducerState = {
  campaignGroups: [],
  selectedPlan: {},
};

function campaignReducer(state = campaignReducerState, action) {
  switch (action.type) {
    case "UPDATE_GROUP":
      return {
        ...state,
        campaignGroups: action.payload,
      };

    case "SELECTED_PLAN": {
      return {
        ...state,
        selectedPlan: action.payload,
      };
    }

    default:
      return state;
  }
}

const subscriptionPlansState = {
  subscriptionPlans: [],
};

function subscriptionPlanreducer(state = subscriptionPlansState, action) {
  switch (action.type) {
    case "SET_SUBSCRIPTION_PLANS":
      return {
        ...state,
        subscriptionPlans: action.payload,
      };

    default:
      return state;
  }
}
export default {
  settingsReducer,
  storyReducer,
  userReducer,
  campaignReducer,
  subscriptionPlanreducer,
};
export const store = createStore(subscriptionPlanreducer);
