export function getTheme() {
  const theme = window.localStorage.getItem("theme");

  return !!(theme && theme === "dark");
}

export function setTheme(theme) {
  window.localStorage.setItem("theme", theme);
}

export function setDefaultTheme(theme) {
  window.localStorage.setItem("defaultTheme", theme);
}
export function getDefaultTheme() {
  const defaultTheme = window.localStorage.getItem("defaultTheme");
  return defaultTheme && defaultTheme;
}
export function getRecentSearches(theme) {
  const text = window.localStorage.getItem("search");
  const list = text ? JSON.parse(text) : []; // text should be array type

  return Array.isArray(list) ? list : []; // check if its an array
}

export function setRecentSearches(list) {
  window.localStorage.setItem("search", JSON.stringify(list));
}
