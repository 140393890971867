import React from "react";
import { any, string, bool } from "prop-types";

const LinkView = ({ className = "", link, children, openInNewTab = true, isExternal = true, id, ariaLabel }) => {
  const params = {};

  if (link === "https://www.thequint.com/fit" || link === "https://hindi.thequint.com/fit") {
    isExternal = false;
  }

  if (openInNewTab) {
    params.target = "_blank";
    if (isExternal) {
      params.rel = "nofollow";
    }
  }

  return (
    <a id={id} className={className} href={link} aria-label={ariaLabel} {...params}>
      {children}
    </a>
  );
};

LinkView.propTypes = {
  children: any.isRequired,
  link: string.isRequired,
  className: string,
  openInNewTab: bool,
  isExternal: bool,
  id: string,
  ariaLabel: string,
};

export default LinkView;
