import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import { bool, string, number, object } from "prop-types";
import YouTube from "react-youtube";

import { getDmVideo } from "../../helper/api";

import "./also-watch.m.css";

const DmPlayer = ({ playListId }) => {
  // eslint-disable-next-line no-unused-vars
  const [videoId, setVideoId] = useState();

  useEffect(() => {
    getDmVideo(playListId)
      .then((res) => res.json())
      .then((playList) => setVideoId(get(playList, ["list", "0", "id"], null)));
  }, []);

  return null;
  // <div styleName="player">{videoId && <Dailymotion video={videoId} autoplay autoplayQueue showQueue mute />}</div>
  // );
};

DmPlayer.propTypes = {
  playListId: string,
};

const YoutubePlayer = ({ playListId }) => {
  const options = {
    playerVars: {
      autoplay: 1,
      mute: 1,
      muted: 1,
      playsinline: 1,
      listType: "playlist",
      list: playListId,
    },
  };

  const onReady = (e) => {
    e.target.playVideo();
  };

  return (
    <div styleName="player">
      <YouTube opts={options} onReady={onReady} />
    </div>
  );
};

YoutubePlayer.propTypes = {
  playListId: string,
};

const AlsoWatch = ({ enabled, hideInMobile, index, publisherName, type, alsoWatch, pageType }) => {
  // if (!enabled || (index !== 0 && global.width < 768 && hideInMobile)) return null;

  // const Player = type === "youtube" ? YoutubePlayer : DmPlayer;
  // const playListId = alsoWatch[type].playlist_id;
  useEffect(() => {
    (function (v, d, o, ai) {
      ai = d.createElement("script");
      ai.defer = true;
      ai.async = true;
      ai.src = v.location.protocol + o;
      d.head.appendChild(ai);
    })(window, document, "//a.vdo.ai/core/v-thequint/vdo.ai.js");
  }, []);

  return (
    <div styleName="wrapper">
      <div id="v-thequint"></div>
    </div>
  );
};

AlsoWatch.propTypes = {
  enabled: bool,
  hideInMobile: bool,
  type: string,
  alsoWatch: object,
  index: number,
  publisherName: string,
  pageType: string,
};

const mapStateToProps = (state) => {
  const hideInMobile = get(state, ["qt", "config", "publisher-attributes", "hide_in_mobile", "also_watch"], false);
  const publisherAttributes = get(state, ["qt", "config", "publisher-attributes"]);
  const item = publisherAttributes.also_watch;
  const pageType = get(state, ["qt", "pageType"], null);

  return {
    enabled: item.enabled,
    type: item.type,
    alsoWatch: item,
    publisherName: publisherAttributes.name,
    hideInMobile,
    pageType,
  };
};

export default connect(mapStateToProps, null)(AlsoWatch);
