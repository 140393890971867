import React from "react";
import { number } from "prop-types";

const CrossIcon = ({ width = 30, height = 30 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 30 30">
    <path d="M0 0h30v30H0z" fill="rgba(255,255,255,0)" />
    <path
      d="M0 0l20 20"
      transform="translate(5 5)"
      fill="none"
      stroke="#292929"
      strokeLinecap="round"
      strokeWidth="3px"
    />
    <path
      d="M0 0l19.54 19.54"
      transform="rotate(90 9.77 15)"
      fill="none"
      stroke="#292929"
      strokeLinecap="round"
      strokeWidth="3px"
    />
  </svg>
);

CrossIcon.propTypes = {
  width: number,
  height: number
};

export default CrossIcon;
