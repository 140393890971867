import React from "react";
import { func, array, bool, object, string } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import translate from "../../translate";

import CrossIcon from "../../atoms/icons/cross-icon";

import "./settingsView.m.css";

const SettingsView = ({
  navigateToPage,
  closePopup,
  showAlert,
  isExistingPlans,
  giftMembership,
  showGiftMessage,
  publisherName,
}) => {
  const dashboardClick = () => {
    closePopup();
    navigateToPage("/dashboard");
  };

  const bookMarkClick = () => {
    closePopup();
    navigateToPage("/saved-stories");
  };
  return (
    <div styleName="wrapper">
      <div styleName="content-link-wrapper">
        <div styleName="settings-link" onClick={dashboardClick}>
          {translate(publisherName, "My_Profile")}
        </div>
        {giftMembership !== null && (
          <div styleName="settings-link" onClick={showGiftMessage}>
            {translate(publisherName, "Message_Gifter")}
            {showAlert && <span className="alert-dot" styleName="alert-dot"></span>}
          </div>
        )}
        <div styleName="settings-link" onClick={bookMarkClick}>
          {translate(publisherName, "Saved_Stories")}
        </div>
        <a styleName="settings-link" href="/contact-us">
          {translate(publisherName, "Contact_Us")}
        </a>
        <a styleName="settings-link" href="/membership/faq">
          {translate(publisherName, "FAQ")}
        </a>
      </div>

      <div styleName="cross-icon" onClick={closePopup}>
        <CrossIcon width={20} height={20} />
      </div>
    </div>
  );
};

SettingsView.propTypes = {
  navigateToPage: func,
  closePopup: func,
  myPlans: array,
  showAlert: bool,
  isExistingPlans: array,
  giftMembership: object,
  showGiftMessage: func,
  publisherName: string,
};

const mapStateToProps = (state) => {
  return {
    isExistingPlans: get(state, ["userReducer", "isExistingPlans"], []),
    giftMembership: get(state, ["userReducer", "giftMembership"], null),
    publisherName: get(state, ["qt", "config", "publisher-attributes", "name"], null),
  };
};
export default connect(mapStateToProps, null)(SettingsView);
