/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from "react";
import { array, string, bool, number, object } from "prop-types";
import get from "lodash/get";
import { throttle } from "lodash";
import { connect } from "react-redux";

import MenuItem from "../../atoms/menu-item";
import HamburgerIcon from "../../atoms/icons/hamburger-icon";

import translate from "../../translate";

import "./secondary-menu.m.css";

const SecondaryMenu = ({
  id,
  items,
  pageType,
  publisherName,
  isHomeMenuRequired,
  currentHostUrl,
  firstMenuItemsLength,
  showHamburgerIcon,
  member,
  isActivePlan,
  activePlanId,
  navbarNumber,
}) => {
  if (!(items && items.length)) {
    return null;
  }

  const [numberOfFirstMenuItems, setNumberOfFirstMenuItems] = useState(firstMenuItemsLength);

  useEffect(() => {
    window && window.innerWidth < 767
      ? setNumberOfFirstMenuItems(items.length)
      : setNumberOfFirstMenuItems(firstMenuItemsLength);
  }, []);

  const firstMenuItems = items.slice(0, numberOfFirstMenuItems);
  const subMenuItems = items.slice(numberOfFirstMenuItems, items.length);

  const SubMenuItemView = () => (
    <ul styleName="sub-menu">
      {subMenuItems.map((item, index) => (
        <li className={`top-navbar-${firstMenuItems.length + index + 1}`} key={index} styleName="sub-menu-item">
          <MenuItem item={item} currentHostUrl={currentHostUrl} />
        </li>
      ))}
    </ul>
  );

  const onHamburgerClick = () => {
    document.getElementById("mySidenav").style.left = "0";
    document.body.style.overflowY = "hidden";
  };

  const MouseOver = () => () => {
    const ele = document.getElementsByClassName("mobile-top-nav-item")[0];
    ele.style.overflow = "visible";
  };

  const MouseOut = () => () => {
    const ele = document.getElementsByClassName("mobile-top-nav-item")[0];
    ele.style.overflow = "auto";
  };

  const prevScrollY = useRef(0);
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    function watchScroll() {
      const handleScroll = () => {
        const scrollY = window.scrollY;
        if (scrollY > 75) {
          if (scrollY > prevScrollY.current) {
            // Scrolling down
            setVisible(false);
          } else {
            // Scrolling up
            setVisible(true);
          }
        } else {
          setVisible(true);
        }
        prevScrollY.current = scrollY;
      };

      window.addEventListener("scroll", throttle(handleScroll, 50));

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }

    watchScroll();
  }, []);

  return (
    <div
      styleName={visible ? `second-row visible ${navbarNumber === 2 ? "bleowMenu" : "abovMenu"}` : "second-row hidden"}
      id={id}
    >
      {showHamburgerIcon ? (
        <div styleName="hamburger-icon" onClick={onHamburgerClick}>
          <HamburgerIcon width={26} />
        </div>
      ) : null}
      <ul styleName="wrapper" className="mobile-top-nav-item">
        {/* Show Home as default */}
        {/* {isHomeMenuRequired ? (
          <li
            className="top-navbar-home"
            styleName={`menu-item hide-menu-item ${pageType === "home-page" ? "highlighted-menu-item" : ""}`}
          >
            <Link href="/">{translate(publisherName, "home")}</Link>
          </li>
        ) : null} */}

        {firstMenuItems.map((item, index) => {
          if (item.data && item.data["icon-code"] && item.data["icon-code"].split(",")[3] !== "null") {
            return (
              <li styleName="menu-item" key={index}>
                <div styleName="prime-overlay" onTouchStart={MouseOver()} onTouchEnd={MouseOut()}>
                  <MenuItem item={item} currentHostUrl={currentHostUrl} />
                  <div styleName="sub-menu powered-by">
                    {item.title === "#SandeshToSoldiers" ? <span>Presented By</span> : <span>Powered By</span>}
                    <img src={item.data["icon-code"].split(",")[3]} />
                  </div>
                </div>
              </li>
            );
          }

          const headerId = "ga4-header-" + navbarNumber + index;
          return (
            <li className={`top-navbar-${index + 1}`} key={index} styleName={`menu-item ${index <= 5 ? "" : ""}`}>
              <MenuItem id={headerId} item={item} currentHostUrl={currentHostUrl} />
            </li>
          );
        })}

        {/* Show remaining items in More */}
        {subMenuItems.length ? (
          <li styleName="menu-item hide-menu-item">
            <div styleName="more-menu">
              <div styleName="down-arrow-icon">{translate(publisherName, "more")}</div>
              <SubMenuItemView />
            </div>
          </li>
        ) : null}
      </ul>
    </div>
  );
};

SecondaryMenu.defaultProps = {
  isHomeMenuRequired: true,
  firstMenuItemsLength: 9,
  showHamburgerIcon: false,
};

SecondaryMenu.propTypes = {
  id: string,
  items: array,
  pageType: string,
  publisherName: string,
  isHomeMenuRequired: bool,
  currentHostUrl: string,
  firstMenuItemsLength: number,
  showHamburgerIcon: bool,
  member: object,
  isActivePlan: number,
  activePlanId: number,
  navbarNumber: number,
};

const mapStateToProps = (state) => ({
  member: state.member,
  isActivePlan: get(state, ["userReducer", "isActivePlan"], 0),
  activePlanId: get(state, ["userReducer", "activePlanId"], null),
});

export default connect(mapStateToProps, null)(SecondaryMenu);
