import { useEffect, memo } from "react";
import useScript from "../../utils/use-script";
import { validateUser } from "../../helper/api";
import { string, func, object, bool } from "prop-types";
import { setAccessTypeUser } from "../../helper/access-type";
import { checkIfAlreadyMemeber, facebookPixel } from "../../utils/utils";

const scriptFlag = "__googleOneTapScript__";
const googleClientScriptURL = "https://accounts.google.com/gsi/client";

const OneTapLogin = ({
  clientId,
  nonce,
  context,
  autoSelect,
  promptParentId,
  stateCookieDomain,
  cancelOnTapOutside,
  nativeCallback,
  redirectURL,
  disable,
  setMember,
}) => {
  const script = useScript(googleClientScriptURL);

  const handleCallback = ({ credentialsResponse, redirectURL }) => {
    validateUser(credentialsResponse.credential, redirectURL).then(async (res) => {
      const USER_STATUS = await checkIfAlreadyMemeber(res.user.email, res.user.name);
      const { addSignUpLoginToDLNew } = require("../../helper/datalayer-utils");
      if (USER_STATUS === "Member already exists") {
        addSignUpLoginToDLNew("login", res.user, window.location.pathname, "one-tap");
        const fbData = {
          content_name: res.user.email,
          currency: "INR",
          predicted_ltv: "1",
          value: 0,
        };
        facebookPixel("CompleteRegistration", fbData);
      } else {
        facebookPixel("CompleteRegistration");
        addSignUpLoginToDLNew("sign_up", res.user,window.location.pathname, "one-tap");
      }

      setMember(res.user);
      global.AccessType &&
        setAccessTypeUser(res.user.email, res.user.metadata.userData?.mobileNumber, res.user.id, res.user.name)
          .then((d) => console.log("User set on AT post OneTap", d))
          .catch((e) => console.error("err setting AT user:-", e));
    });
  };

  useEffect(() => {
    if (!disable) {
      if (!window[scriptFlag] && script === "ready") {
        window.google.accounts.id.initialize({
          nonce,
          context,
          client_id: clientId,
          auto_select: autoSelect,
          prompt_parent_id: promptParentId,
          state_cookie_domain: stateCookieDomain,
          cancel_on_tap_outside: cancelOnTapOutside,
          native_callback: nativeCallback,
          callback: (res) => handleCallback({ credentialsResponse: res, redirectURL }),
        });
        window[scriptFlag] = true;
        // UI Prompt isn't showing after delaying the script load,
        // So, Manually Triggering the UI
        window.google?.accounts?.id?.prompt();
      }
    } else {
      window.google?.accounts?.id?.cancel();
    }
  }, [
    script,
    disable,
    nonce,
    context,
    clientId,
    autoSelect,
    promptParentId,
    stateCookieDomain,
    cancelOnTapOutside,
    nativeCallback,
    redirectURL,
  ]);

  return null;
};

OneTapLogin.propTypes = {
  clientId: string,
  nonce: object,
  context: object,
  autoSelect: string,
  promptParentId: string,
  stateCookieDomain: string,
  cancelOnTapOutside: string,
  nativeCallback: string,
  redirectURL: string,
  disable: bool,
  setMember: func,
};

export default memo(OneTapLogin);
