import english from "./english.json";
import hindi from "./hindi.json";
import fit from "./fit.json";

const translate = (publisherName, name) => {
  switch (publisherName) {
    case "quint":
      return english[name];
    case "quint-hindi":
      return hindi[name];
    case "fit":
      return fit[name] || english[name];
    default:
      return english[name];
  }
};

export default translate;
