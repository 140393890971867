/* eslint-disable max-len */
import React from "react";

export const GiftBoxIconOne = () => {
  return (
    <svg width="158" height="194" viewBox="0 0 158 194" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2802_359)">
        <g filter="url(#filter0_d_2802_359)">
          <path
            d="M149.004 35.5703H9.00439C6.24297 35.5703 4.00439 37.8089 4.00439 40.5703V180.57C4.00439 183.332 6.24297 185.57 9.00439 185.57H149.004C151.766 185.57 154.004 183.332 154.004 180.57V40.5703C154.004 37.8089 151.766 35.5703 149.004 35.5703Z"
            fill="#E3120B"
          />
          <path
            d="M149.004 35.8203H9.00439C6.38104 35.8203 4.25439 37.947 4.25439 40.5703V180.57C4.25439 183.194 6.38104 185.32 9.00439 185.32H149.004C151.628 185.32 153.754 183.194 153.754 180.57V40.5703C153.754 37.947 151.628 35.8203 149.004 35.8203Z"
            stroke="black"
            strokeWidth="0.5"
          />
        </g>
        <path d="M4 112.07H79" stroke="#FEBF0F" strokeWidth="11" />
        <path d="M79 112.07H154" stroke="#FEBF0F" strokeWidth="11" />
        <path d="M80.5044 111.57V36.5703" stroke="#FEBF0F" strokeWidth="11" />
        <path
          d="M81.0349 35.7372C81.0349 35.7372 133.79 22.3753 118.94 8.80306C104.091 -4.76921 56.0044 66.5725 56.0044 66.5725"
          stroke="#FEBF0F"
          strokeWidth="9"
        />
        <path
          d="M86.6579 39.3116C86.6579 39.3116 36.8718 17.3358 53.7774 6.43113C70.683 -4.47356 105.504 66.5707 105.504 66.5707"
          stroke="#FEBF0F"
          strokeWidth="9"
        />
        <path d="M80.5044 185.57V110.57" stroke="#FEBF0F" strokeWidth="11" />
      </g>
      <defs>
        <filter
          id="filter0_d_2802_359"
          x="0.00439453"
          y="35.5703"
          width="158"
          height="158"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2802_359" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2802_359" result="shape" />
        </filter>
        <clipPath id="clip0_2802_359">
          <rect width="158" height="194" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const GiftBoxIconTwo = () => {
  return (
    <svg width="158" height="159" viewBox="0 0 158 159" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2802_383)">
        <g filter="url(#filter0_d_2802_383)">
          <path
            d="M149.004 0.136719H9.00439C6.24297 0.136719 4.00439 2.37529 4.00439 5.13672V145.137C4.00439 147.898 6.24297 150.137 9.00439 150.137H149.004C151.766 150.137 154.004 147.898 154.004 145.137V5.13672C154.004 2.37529 151.766 0.136719 149.004 0.136719Z"
            fill="#E3120B"
          />
          <path
            d="M149.004 0.386719H9.00439C6.38104 0.386719 4.25439 2.51337 4.25439 5.13672V145.137C4.25439 147.76 6.38104 149.887 9.00439 149.887H149.004C151.628 149.887 153.754 147.76 153.754 145.137V5.13672C153.754 2.51337 151.628 0.386719 149.004 0.386719Z"
            stroke="black"
            strokeWidth="0.5"
          />
        </g>
        <path d="M4 76.6367H79" stroke="#FEBF0F" strokeWidth="11" />
        <path d="M79 76.6367H154" stroke="#FEBF0F" strokeWidth="11" />
        <path d="M80.5044 76.1367V1.13672" stroke="#FEBF0F" strokeWidth="11" />
        <path d="M80.5044 150.137V75.1367" stroke="#FEBF0F" strokeWidth="11" />
      </g>
      <defs>
        <filter
          id="filter0_d_2802_383"
          x="0.00439453"
          y="0.136719"
          width="158"
          height="158"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2802_383" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2802_383" result="shape" />
        </filter>
        <clipPath id="clip0_2802_383">
          <rect width="158" height="159" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const GiftBoxIconThree = () => {
  return (
    <svg width="158" height="159" viewBox="0 0 158 159" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2802_391)">
        <g filter="url(#filter0_d_2802_391)">
          <path
            d="M149.004 0.703125H9.00439C6.24297 0.703125 4.00439 2.9417 4.00439 5.70312V145.703C4.00439 148.465 6.24297 150.703 9.00439 150.703H149.004C151.766 150.703 154.004 148.465 154.004 145.703V5.70312C154.004 2.9417 151.766 0.703125 149.004 0.703125Z"
            fill="#E3120B"
          />
          <path
            d="M149.004 0.953125H9.00439C6.38104 0.953125 4.25439 3.07977 4.25439 5.70312V145.703C4.25439 148.326 6.38104 150.453 9.00439 150.453H149.004C151.628 150.453 153.754 148.326 153.754 145.703V5.70312C153.754 3.07977 151.628 0.953125 149.004 0.953125Z"
            stroke="black"
            strokeWidth="0.5"
          />
        </g>
        <path d="M80.5044 76.7031V1.70312" stroke="#FEBF0F" strokeWidth="11" />
        <path d="M80.5044 150.703V75.7031" stroke="#FEBF0F" strokeWidth="11" />
      </g>
      <defs>
        <filter
          id="filter0_d_2802_391"
          x="0.00439453"
          y="0.703125"
          width="158"
          height="158"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2802_391" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2802_391" result="shape" />
        </filter>
        <clipPath id="clip0_2802_391">
          <rect width="158" height="159" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const GiftBoxIconFour = () => {
  return (
    <svg width="158" height="159" viewBox="0 0 158 159" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2802_397)">
        <g filter="url(#filter0_d_2802_397)">
          <path
            d="M149.004 0.273438H9.00439C6.24297 0.273438 4.00439 2.51201 4.00439 5.27344V145.273C4.00439 148.035 6.24297 150.273 9.00439 150.273H149.004C151.766 150.273 154.004 148.035 154.004 145.273V5.27344C154.004 2.51201 151.766 0.273438 149.004 0.273438Z"
            fill="#E3120B"
          />
          <path
            d="M149.004 0.523438H9.00439C6.38104 0.523438 4.25439 2.65008 4.25439 5.27344V145.273C4.25439 147.897 6.38104 150.023 9.00439 150.023H149.004C151.628 150.023 153.754 147.897 153.754 145.273V5.27344C153.754 2.65008 151.628 0.523438 149.004 0.523438Z"
            stroke="black"
            strokeWidth="0.5"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_2802_397"
          x="0.00439453"
          y="0.273438"
          width="158"
          height="158"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2802_397" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2802_397" result="shape" />
        </filter>
        <clipPath id="clip0_2802_397">
          <rect width="158" height="159" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
