import React from "react";

const EmailIcon = () => (
  <svg className="email-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.477 16.107" width="21" height="16">
    <path
      fill="#bababa"
      d="M20.805 0H.671A.634.634 0 000 .671v14.765a.634.634 0 00.671.671h20.134a.634.634 0 00.671-.671V.671A.634.634 0 0020.805 0zm-1.61 3.221L11.141 8.59a.954.954 0 01-.4.134.954.954 0 01-.4-.134L2.282 3.221a.71.71 0 01-.134-.94.71.71 0 01.94-.134l7.651 5.1 7.651-5.1a.66.66 0 01.94.2.636.636 0 01-.135.874z"
    />
  </svg>
);

export default EmailIcon;
