import React, { useRef, useState } from "react";
import { func, bool, string } from "prop-types";

import EmailIcon from "../../atoms/icons/email";
import LineLeftArrow from "../../atoms/icons/line-left-arrow";
import KeyIcon from "../../atoms/icons/key-icon";

import { forgotPassword, resetPassword } from "../../helper/api";

import "./forgot-password.m.css";
import translate from "../../translate";

const ForgotPasswordView = ({ toggleForgotPasswordView, fromResetPassword = false, token = "", publisherName }) => {
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const [sentVerification, setSentVerification] = useState(false);
  const [errorMsg, setError] = useState("");

  async function handleForgotPassword(e) {
    e.preventDefault();

    forgotPassword(emailRef.current.value)
      .then((msg) => {
        showFormAgain();
      })
      .catch((e) => console.log("something went wrong", e));
  }

  const handleResetPassword = (e) => {
    e.preventDefault();
    const newPassword = passwordRef.current.value;
    const confirmNewPassword = confirmPasswordRef.current.value;
    if (newPassword !== confirmNewPassword) {
      setError("Password doesn't match");
      return;
    }
    resetPassword(token, passwordRef.current.value, confirmPasswordRef.current.value)
      .then((item) => {
        window.location.href = "/";
      })
      .catch((e) => console.log("reset password failed", e));
  };

  function showFormAgain() {
    setSentVerification(!sentVerification);
  }

  const RenderBodyView = () => {
    if (fromResetPassword) {
      return (
        <>
          <p styleName="title">{translate(publisherName, "new_password")}</p>
          <form onSubmit={handleResetPassword}>
            <div styleName="box-wrapper">
              <KeyIcon />
              <input
                type="password"
                onFocus={() => setError("")}
                styleName="input-fields"
                ref={passwordRef}
                placeholder={translate(publisherName, "password")}
                required
              />
            </div>
            <div styleName="box-wrapper">
              <KeyIcon />
              <input
                type="password"
                styleName="input-fields"
                ref={confirmPasswordRef}
                placeholder={translate(publisherName, "re_enter_pwd")}
                required
                onFocus={() => setError("")}
              />
            </div>
            {errorMsg && <p styleName="error">{errorMsg}</p>}
            <div>
              <input type="submit" styleName="send-btn" value={translate(publisherName, "send")} />
            </div>
          </form>
        </>
      );
    } else if (!sentVerification) {
      return (
        <>
          <p styleName="title">{translate(publisherName, "forgot_pwd_email")}</p>
          <form onSubmit={handleForgotPassword}>
            <div styleName="box-wrapper">
              <EmailIcon />
              <input
                type="email"
                styleName="input-fields"
                ref={emailRef}
                placeholder={translate(publisherName, "email")}
                required
              />
            </div>
            <div>
              <input type="submit" styleName="send-btn" value={translate(publisherName, "send")} />
            </div>
          </form>
          <b styleName="to-login" onClick={toggleForgotPasswordView}>
            <LineLeftArrow />
            Back to Login
          </b>
          <center styleName="bottom-text">
            {translate(publisherName, "facing_issue")}
            <div>
              <a href="mailto:tqmembersonly@thequint.com" target="_blank">
                {translate(publisherName, "forgot_pwd_contact")}
              </a>
            </div>
          </center>
        </>
      );
    } else {
      return (
        <>
          <p styleName="title">A Message With Further Instructions Has Been Sent To Your Email Address.</p>
          <center styleName="bottom-text">
            <b styleName="contact-us" onClick={showFormAgain}>
              Change Email Address
            </b>
          </center>
        </>
      );
    }
  };

  return (
    <div styleName="wrapper">
      <div styleName={`flex-col-spaced ${sentVerification ? "center" : ""}`}>{RenderBodyView()}</div>
    </div>
  );
};

ForgotPasswordView.propTypes = {
  toggleForgotPasswordView: func,
  fromResetPassword: bool,
  token: string,
  publisherName: string,
};

export default ForgotPasswordView;
