import React from "react";
import { number } from "prop-types";

const HamburgerIcon = ({ width }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 36 36">
    <g transform="translate(1.75 1.75)">
      <path d="M0 0h28" fill="#35144f" stroke="#35144f" strokeLinecap="round" strokeWidth="3.5px" />
      <path
        d="M0 0h28"
        fill="#35144f"
        stroke="#35144f"
        strokeLinecap="round"
        strokeWidth="3.5px"
        transform="translate(0 10.984)"
      />
      <path
        d="M6 0h20"
        fill="#35144f"
        stroke="#35144f"
        strokeLinecap="round"
        strokeWidth="3.5px"
        transform="translate(-5.897 22)"
      />
    </g>
  </svg>
);

HamburgerIcon.propTypes = {
  width: number,
  height: number,
};

export default HamburgerIcon;
