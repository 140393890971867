import React from "react";
import { string, number } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import { Link } from "@quintype/components";

import FitHindiColorLogo from "../../atoms/icons/fit-hindi";
import FitColorLogo from "../../atoms/icons/fit-color-logo";
import TheQuintLogo from "../../atoms/icons/the-quint-logo";
import QuintHindiLogo from "../../atoms/icons/quint-hindi-logo";
import LinkView from "../../atoms/link-view";

import "./mobile-logos.m.css";

const PopupMobileLogoView = ({ isActivePlan, activePlanId, publisherName, currentPath }) => {
  const quintLogos = () => {
    return (
      <React.Fragment>
        {currentPath.split("/")[1] === "fit" ? (
          <>
            <Link href="/" className="header-tq-logo engish-quint-logo" aria-label="The Quint Homepage">
              <TheQuintLogo />
            </Link>
            <LinkView
              link="https://hindi.thequint.com/"
              className="header-hq-logo hindi-color-logo"
              ariaLabel="The Quint hindi homepage"
            >
              <QuintHindiLogo color="var(--twentythree-color)" />
            </LinkView>
          </>
        ) : (
          <>
            <LinkView
              link="https://hindi.thequint.com/"
              className="header-hq-logo hindi-color-logo"
              ariaLabel="The Quint hindi homepage"
            >
              <QuintHindiLogo color="var(--twentythree-color)" />
            </LinkView>
            <Link href="/fit" className="fit-color-logo" aria-label="Fit Homepage">
              <FitColorLogo width={52} height={30} isActivePlan={isActivePlan} activePlanId={activePlanId} />
            </Link>
          </>
        )}
      </React.Fragment>
    );
  };

  const hindiLogos = () => {
    return (
      <React.Fragment>
        {currentPath.split("/")[1] === "fit" ? (
          <Link href="/" className="header-hq-logo hindi-color-logo" aria-label="The Quint Hindi Homepage">
            <QuintHindiLogo color="var(--twentythree-color)" />
          </Link>
        ) : (
          <Link href="/fit" className="fit-hindi-color-logo" aria-label="Fit Homepage">
            <FitHindiColorLogo />
          </Link>
        )}
        <LinkView
          link="https://www.thequint.com/"
          className="header-tq-logo engish-quint-logo"
          ariaLabel="The Quint homepage"
        >
          <TheQuintLogo />
        </LinkView>
      </React.Fragment>
    );
  };

  const getLogos = () => {
    switch (publisherName) {
      case "quint":
        return quintLogos();
      case "quint-hindi":
        return hindiLogos();
      default:
        return quintLogos();
    }
  };

  return <div styleName="logo-wrapper">{getLogos()}</div>;
};

PopupMobileLogoView.propTypes = {
  publisherName: string,
  currentPath: string,
  isActivePlan: number,
  activePlanId: number,
};

const mapStateToProps = (state) => {
  return {
    isActivePlan: get(state, ["userReducer", "isActivePlan"]),
    activePlanId: get(state, ["userReducer", "activePlanId"], null),
    currentPath: get(state, ["qt", "currentPath"], ""),
  };
};

export default connect(mapStateToProps, null)(PopupMobileLogoView);
